/* body,
html {
    width: 100%;
    height: 100%;
} */

.confetti {
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.confetti-item {
    position: absolute;
    will-change: transform;
}

@keyframes confetti-fall {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100vh);
    }
}