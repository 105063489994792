:root {
    --color-white: #FFFFFF;
    --color-red: #dc3545;
    --color-light-red: #ffa1ab;
    /* --color-tangaroa: #0A253E; */
    --color-tangaroa: #1d222f;
    --color-teal-blue: #1B4A56;
    --color-persian-green: #13ac95;
    --color-dodger-blue: #2D95F0;
    --color-solitude: #ECEFF6;
    --color-solitude-dark: #c7cbd4;
    --color-gray: #E6EAF3;
    --color-gray-dark: #9DA9B2;
    --color-gray-light: #F9FAFC;
    --color-charlotte: #92DCE2;
    --color-charlotte-dark: #2CC5D1;
    --color-sundown-light: #eeacac33;
    --color-sundown: #D96868;
    --color-chateau-green: #36BC57;
    --color-aero-green-light: #B9F3D0;
    --color-orange: #e38936;
    --color-light-orange: #f6c86a;
    --color-gold: #D8A641;
    --color-dark-blue: #4740C7;
    --color-amethyst: #9661D2;
    --color-snowy-mint: #D9F3D0;
    --color-alice-blue: #F1F5F9;
    --color-alice-blue-100: #F4F7FD;
    --color-pattens-blue: #DAE8F7;

    --circle-ripple-color: rgba(54, 188, 87, 0.5);
    --circle-ripple-color-2: rgba(54, 188, 87, 0);

    /* --bs-primary; */
    --bs-primary: #7b5dd6;
    --bs-btn-hover: #6a4ec1;

}

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css);


/* bootstrap changes  */

/* @import "../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import "../../../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-react-schedule/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css"; */

.custom-loader-img {
    width: 100px;
}

.default-container-wrapper {
    background: #f4f7fb !important;
    min-height: 100%;
}

.invalid-input {
    border: 1px var(--color-red) solid !important;
}

.invalid-input:focus {
    border-color: var(--color-red);
}

.error-label {
    color: var(--color-red) !important;
    font-size: small !important;
    width: 100% !important;
}

/* bootstrap changes  */
.accordion-button::after {
    margin-left: 10px;
}


.fa-btn {
    padding: 0px;
    background: none !important;
    border: none;
}

.continue-button {
    padding: 8px 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    background: var(--bs-primary);
    /* box-shadow: 0px 10px 17px rgba(219, 226, 239, 0.8); */
    border-radius: 6px;
}

.continue-button:hover {
    color: #FFFFFF;
    background: var(--bs-btn-hover);
}


.continue-button:disabled {
    opacity: 0.8;
    color: #FFFFFF;
    background: var(--bs-secondary);
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.back-button {
    padding: 8px 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1px;

    color: #0A253E;

    background: #FFFFFF;
    border: 1px solid #0A253E;
    box-shadow: 0px 10px 17px rgba(219, 226, 239, 0.6);
    border-radius: 6px;
}

.card-profile-box-img {
    width: 100%;
    height: 100%;
}

.people-card {
    flex: 1 0 0%;
    margin: 10px;
    background: var(--color-solitude);
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    padding: 0;
    /* min-width: 480px; */
    min-width: 600px;
    /* 440px */
    /* max-width: 600px; */
}

.people-card:hover {
    box-shadow: 0px 1px 9px 0px #dddedf;
}

.people-card-shadowshadow {
    box-shadow: 0px 1px 5px 0px #dddedf;
}

.people-card-body {
    background: var(--color-white);
    border-bottom: 1px solid var(--color-gray);
    border-radius: 10px;
}

.people-card-body:hover {
    /* background-color: var(--color-gray); */
}

.people-card-info-box {
    padding-left: 20px;
    box-shadow: -15px 0px 10px -15px #e4e8f2
}

.people-card-photo {
    width: 65px;
    aspect-ratio: 1/1;
    padding: 3px;
    background-color: var(--color-white);
    border: 1px solid #F8E3FB;
    border-radius: 18px;
}

.people-card-photo-box {
    height: 100%;
    background: #F8E3FB;
    border-radius: 14px;
    cursor: pointer;
}

.people-card-profile-box-img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
}

.people-card .title {
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: var(--color-tangaroa);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.people-card .detail {
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.7;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.people-card .detail img {
    margin-right: 5px;
}

.people-card .detail-bold {
    color: var(--color-tangaroa);
    opacity: 1;
    font-weight: 500;
    font-size: 11px;
    margin-bottom: 6px;
}

.people-card .status {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 20px;
    gap: 10px;

    position: absolute;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 10px;

    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-tangaroa);
    background: #c5c5c5;
    /* width: 103px; */
}

.people-card-footer {
    display: flex;
    justify-content: space-between;
}

.people-card-footer .item {
    padding-left: 10px;
    padding-right: 10px;
    /* width: 100px;
    height: 100px; */
}

.people-card-footer .detail {
    /* padding-left: 10px; */
}

.people-card-footer .detail .img {
    margin-right: 0px;
}


.people-card .c-checkbox {
    position: absolute;
    right: 1rem;
    top: 35%;
    bottom: 42%;
    transform: rotate(0.82deg);
    z-index: 1;
}




.people-horizontal-card {
    flex: 1 0;
    margin: 5px;
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    min-width: 1320px;
    /* max-width: 1850px; */
    margin: 0.3rem;
}

.people-horizontal-card:hover {
    box-shadow: 0px 1px 9px 0px #dddedf;
}

.people-horizontal-card-body {
    /* background: var(--color-white);
    border-bottom: 1px solid var(--color-gray);
    border-radius: 10px; */
}

.people-horizontal-card-body:hover {
    /* background-color: var(--color-gray); */
}

.people-horizontal-card-info-box {
    padding-left: 20px;
    box-shadow: -15px 0px 10px -15px #e4e8f2
}

.people-horizontal-card-photo {
    width: 75px;
    aspect-ratio: 1/1;
    padding: 3px;
    background-color: var(--color-white);
    border: 1px solid #F8E3FB;
    border-radius: 18px;
}

.people-horizontal-card-photo-box {
    height: 100%;
    background: #F8E3FB;
    border-radius: 14px;
    ;
}

.people-horizontal-card-profile-box-img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
}

.people-horizontal-card .title {
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: var(--color-tangaroa);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.people-horizontal-card .detail {
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.7;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.people-horizontal-card .detail img {
    margin-right: 5px;
}

.people-horizontal-card .detail-bold {
    color: var(--color-tangaroa);
    opacity: 1;
    font-weight: 500;
    font-size: 11px;
    margin-bottom: 6px;
}

.people-horizontal-card .status {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 20px;
    gap: 10px;

    position: absolute;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 10px;

    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-tangaroa);
    background: #c5c5c5;
    /* width: 103px; */
}

.people-horizontal-card-footer .item {
    background-color: var(--color-white)
}

.people-horizontal-card .c-checkbox {
    position: absolute;
    right: 1rem;
    top: 35%;
    bottom: 42%;
    transform: rotate(0.82deg);
    z-index: 1;
}

.status-available {
    background: var(--color-aero-green-light) !important;
}

.status-incomplete {
    background: #FFBFC3 !important;
}

.status-inprogress {
    background: #E4E3E3 !important;
}

.status-active {
    background: #73C0FA !important;
}

.status-dispatched {
    background: #FFCB9A !important;
}

.status-inactive {
    background: #FBE3E3 !important;
}

.status-dnu {
    background: #E1CAFF !important;
}

.status-undispatch {
    background: #e988dd !important;
}

.status-completed {
    background-color: var(--color-aero-green-light) !important;
}

.status-rejected {
    background-color: var(--color-light-red) !important;
}

.status-new {
    background-color: var(--color-charlotte-dark) !important;
}

.status-credit-check {
    background-color: var(--color-chateau-green) !important;
}

.status-safety-check {
    background-color: var(--color-gold) !important;
}

.status-proposal {
    background-color: var(--color-solitude) !important;
}

.status-sa {
    background-color: var(--color-snowy-mint) !important;
}

.status-coi {
    background-color: var(--color-light-orange) !important;
}

.input-search {
    background: #EEF2F8;
    border-radius: 4px;
}

.menu-profile-photo {
    border: 2px solid #CAD3E0;
    border-radius: 44.5px;
    width: 40px;
    height: 40px;
}


.top-nav-bar-notification {
    width: 100%;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10%;
}


.top-nav-bar {
    position: fixed;
    top: 0;
    right: 0;
    left: 100px;
    z-index: 1030;

    background: var(--color-white);
    box-shadow: 0px 1px 0px var(--color-solitude);
}

.top-nav-bar-title-text {
    color: var(--color-tangaroa);
    font-size: 21px;
}

.nav-item-box {
    width: 50px;
    color: var(--color-tangaroa);
    background: var(--color-white);
    border-radius: 8px;
    margin: auto;
    text-align: center;
    cursor: pointer;
}

.nav-item-box-img {
    padding: 25%;
    width: 50px;
    height: 50px;
    background: var(--color-solitude);
    border-radius: 8px;
}

.nav-item-box-img:hover {
    background: var(--color-solitude-dark) !important;
}

.nav-item-box-img.active {
    background: var(--color-tangaroa) !important;
}

.nav-item-box-text {
    /* padding: inherit; */
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-tangaroa);
    padding-top: 5px;
    padding-bottom: 0px;
    text-align: center;
    cursor: pointer;
}

.nav-item-box-text.active {
    color: var(--color-tangaroa) !important;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 21px; */
}

/* dropdown arrow */
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 1em;
    vertical-align: 0.255em;
    content: "";

    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}


/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */


.side-navbar {
    z-index: 999;
    width: 100px;
    height: 100%;
    position: fixed;
    margin-left: -300px;
    background-color: var(--color-white);
    color: var(--color-tangaroa);
    transition: 0.5s;
    filter: drop-shadow(5px 4px 13px rgba(229, 233, 241, 0.56));
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

/* Styling for the scrollbar (for browsers supporting this property) */
.side-navbar::-webkit-scrollbar {
    width: 0px;
    /* Adjust the scrollbar width as needed */
}

.side-navbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Adjust the color as needed */
}

.side-navbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    /* Adjust the hover color as needed */
}

.side-navbar-logo {
    width: 44px;
}

.nav-link {
    display: block;
    padding: 0.5rem 0.5rem;

}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: var(--color-white);
    color: var(--color-tangaroa);
}

.my-container {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 100px;
}

.app-container {
    width: 100%;
    padding-top: 60px;
    overflow-x: hidden;
    height: 100vh;
    background: #f4f7fb !important;
}

.app-container-loader {
    background: rgb(0 0 0 / 20%);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* top: 0; */
    /* left: 0; */
    cursor: wait;
}

.menu-btn {
    background: var(--color-white);
    box-shadow: 0px 4px 14px rgba(207, 213, 226, 0.5);
    border-radius: 4px;
    color: var(--color-tangaroa);
}




.filter-bar {
    min-width: max-content;
    background: var(--color-white);
    box-shadow: 0px 4px 6px rgba(206, 213, 225, 0.3);
}


.c-btn {
    /* margin-right: 10px; */
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    /*20px  */

    color: var(--color-white);

    padding: 10px 14px;
    gap: 10px;

    background: var(--bs-primary);
    border-radius: 6px;
    border: none;

    width: auto;
}

.c-btn:hover {
    background: var(--bs-btn-hover);
}

.c-btn:disabled {
    cursor: not-allowed !important;
    opacity: 0.25 !important;
}

.c-btn-gray {
    background-color: var(--color-solitude) !important;
    color: var(--color-tangaroa) !important;
}

.c-btn-gray:hover {
    background-color: var(--color-solitude-dark) !important;
    color: var(--color-tangaroa) !important;
}

.c-btn-selected {
    background-color: var(--color-charlotte) !important;
    color: var(--color-white) !important;
}

.c-btn-transparent {
    background-color: rgba(255, 255, 255, 0) !important;
    color: var(--color-tangaroa) !important;
}

.c-btn-transparent:hover {
    background-color: var(--color-solitude-dark) !important;
}

.c-border-radius-2 {
    border-radius: 2rem;
}


.c-dropdown {
    background: var(--color-white);
    border: 1px solid #E0E5EE;
    border-radius: 4px;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    padding-right: 50%;
}


.people-more-filters-wrapper {
    z-index: 1;
    margin-top: 61px;
}

@keyframes move-right-to-left {

    /* from {
        left: 400px;
    }
    to {
        left: 0px;
    } */
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes move-left-to-right {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}


.people-more-filters-card.open {
    animation: move-right-to-left 1.5s;
}

.people-more-filters-card.close {
    animation: move-left-to-right 1.5s;
}

.people-more-filters-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-white);
    background-clip: border-box;
    border-radius: 0.25rem;
    background: var(--color-white);
    box-shadow: -13px 0px 15px rgba(229, 233, 241, 0.7);
    height: calc(100vh - 63.5px);
}

.people-more-filters-card-body {
    padding: 1rem 1.5rem;
    overflow: auto;
}

.people-more-filters-card-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: var(--color-tangaroa);
}

.people-more-filters-card-sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.people-more-filters-card-semi-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
}

.list-group li {
    display: block;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.7rem 1.5rem;
    color: var(--color-tangaroa);
    text-decoration: none;
    background-color: var(--color-white);

    border-top: none;
    border-left: none;
    border-bottom: 1px dashed #DADFEC;
    border-right: none;

    font-weight: 500;
    font-size: 14px;
}

.list-group-item-card {
    display: flex;
    align-items: center;
    background: var(--color-white);
    border: 1px solid rgba(191, 199, 219, 0.3);
    border-radius: 10px;
    padding: 1rem;
}

.list-group-item-card .icon-img {
    width: 26px;
    height: 26px;
}

.list-group-item-card .bold-text {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-tangaroa);
}

.list-group-item-card .light-text {
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: var(--color-tangaroa);
    opacity: 0.4;
    padding-right: 0.5rem;
    width: auto;
}

.dark-btn {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 10px; */

    border: 1px solid var(--color-tangaroa);
    color: var(--color-white);
    background: var(--color-tangaroa);
    /* border-radius: 6px;

    flex: none;
    order: 1;
    flex-grow: 0; */
}

.white-btn {
    border: 1px solid var(--color-tangaroa);
    color: var(--color-tangaroa);
    background: var(--color-white);
}

.transparent-btn {
    color: var(--color-charlotte-dark);
    background: none !important;
}

.close-btn {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: var(--color-white);
    box-shadow: 0px 4px 14px rgba(207, 213, 226, 0.5);
    border-radius: 4px;
    width: fit-content;
}


.people-detail-card {
    overflow: hidden;
    border-radius: 10px;
    min-height: 845px;
    max-height: 845px;
    min-width: 293px;
    /* min-width: 330px; */
    z-index: 1;
}

.people-detail-card-header {
    background-color: var(--color-gray)
}

.people-detail-card-status-top {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: var(--color-tangaroa);
    background-color: var(--color-white);
    padding: 0.1rem 0.75rem;
    width: fit-content;

    position: absolute;
    top: 0.5em;
    right: 0.5em;
    border-radius: 5px;
}

.people-detail-card-profile {
    background: var(--color-white);
    padding: 3px;
    border-radius: 12px;
}

.people-detail-card-profile-box {
    border-radius: 9px;
    overflow: hidden;
    outline: thick;
}

.people-detail-card-profile-box img {
    width: 65px;
    height: 65px;
}

.people-detail-card-profile-box-badge {
    border: 3px solid var(--color-solitude-dark) !important;
    background: var(--color-white);
    top: 92% !important;
    left: 97% !important;
}

.people-detail-card-profile-box-badge IMG {
    width: 12PX;
    height: 12PX;
}

.people-detail-card-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: var(--color-tangaroa);
}

.people-detail-card p {
    margin-bottom: 0.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-tangaroa);
}

.people-detail-card-label {
    color: var(--color-tangaroa);
    opacity: 0.4;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
}

.people-detail-card-label-2 {
    color: var(--color-tangaroa);
    opacity: 0.6;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
}

.people-detail-card-text {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 18px;
    color: var(--color-tangaroa);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.people-detail-card-phone {
    font-size: 13px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.people-detail-card-location {
    font-size: 14px;
    color: var(--color-tangaroa);
    opacity: 0.8;
}

.people-detail-card-code {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
}

.people-detail-card-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.people-detail-card-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: var(--color-gray);
    border-radius: 6px;
}

.people-detail-card-badge-pink {
    background: var(--color-sundown-light) !important;
    color: var(--color-sundown) !important;
}

.people-detail-card-badge-green {
    background: var(--color-aero-green-light) !important;
    color: var(--color-chateau-green) !important;
}

.people-detail-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
    width: 40px;
    height: 40px;
    background: var(--color-solitude);
    border-radius: 8px;
}

.people-detail-card-sub-wrapper {
    z-index: -1;
}

.people-detail-card-sub {
    padding: 1rem 1.4rem;
    border-bottom: 1px solid var(--color-solitude);
    background-color: var(--color-white);
}

.people-detail-card select {
    border: 1px solid var(--color-aero-green-light);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    box-sizing: border-box;
    background-color: var(--color-aero-green-light);
    border-radius: 10px;
}

.people-detail-card select option {
    background-color: var(--color-white);
    border-radius: 10px;
}

.people-detail-card-select {
    border: 1px solid var(--color-aero-green-light);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    box-sizing: border-box;
    background-color: var(--color-aero-green-light);
    border-radius: 10px;
    line-height: 1.5;
    padding: .375rem 1rem .375rem .75rem;
}

.people-detail-card-select option {
    background-color: var(--color-white);
    border-radius: 10px;
}

.people-detail-card-nav {
    box-shadow: inset 0px -4px 4px rgb(205 212 225 / 32%);
}

/* .people-detail-card-nav-btn {
    border: none;
    background: none;
    font-size: 16px;
    line-height: 36px;
    width: 50%;
    font-weight: 400;
}

.people-detail-card-nav-btn.active {
    font-weight: 600;
    line-height: inherit;
    color: var(--color-chateau-green);
    border-bottom: 3px solid var(--color-chateau-green);
} */

.people-detail-card-nav {
    display: flex;
}

.people-detail-card-nav-btn {
    border: none;
    background: none;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    font-weight: 400;
}

.people-detail-card-nav-btn:nth-child(1),
.people-detail-card-nav-btn:nth-child(2) {
    width: 40%;
}

.people-detail-card-nav-btn:nth-child(3) {
    width: 20%;
}

.people-detail-card-nav-btn.active {
    font-weight: 600;
    color: #36BC57;
    border-bottom: 3px solid #36BC57;
}


.people-detail-card-search {
    position: relative;
    box-shadow: 0 0 10px rgba(51, 51, 51, .1);
    z-index: -1;
}

.people-detail-card-search input {
    height: 40px;
    text-indent: 25px;
    border: 1px solid #d6d4d4;
}

.people-detail-card-search input:focus {
    box-shadow: none;
    border: 2px solid #EDF1FA;
}

.people-detail-card-search .fa-search {
    position: absolute;
    top: 10px;
    left: 16px;
}

.people-detail-card-search button {
    position: absolute;
    top: 7px;
    right: 7px;
    border: none;
    background: none;
}





.nav-tabs {
    border-bottom: 6px solid var(--color-charlotte) !important;
}

.nav-link-people {
    margin-right: 10px;
    margin-bottom: 0px !important;
    background: var(--color-white) !important;
    color: var(--color-tangaroa);
    border: 1px solid var(--color-white);
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    font-size: 13px;
}

.nav-link-people:hover {
    background-color: var(--color-charlotte) !important;
    border: 1px solid var(--color-charlotte) !important;
}

.nav-link-people.active {
    background-color: var(--color-charlotte) !important;
    border: 1px solid var(--color-charlotte) !important;

    font-weight: 600;
    font-size: 14px;
    color: var(--color-white) !important;
}

.badge-circle {
    padding: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-charlotte);
}

.p-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-tangaroa);
}

.p-changed {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
    opacity: 0.6;

}

.p-status {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-orange);

}

.p-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
}

.p-dark-gray {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.8;
}

.p-light-gray {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.bg-blue {
    background-color: var(--color-dodger-blue) !important;
}

.bg-dark-blue {
    background-color: var(--color-dark-blue) !important;
}

.bg-purple {
    background-color: var(--color-amethyst) !important;
}

.bg-light-orange {
    background-color: var(--color-light-orange) !important;
}

.bg-orange {
    background-color: var(--color-orange) !important;
}

.bg-green {
    background-color: var(--color-persian-green) !important;
}

.bg-gold {
    background-color: var(--color-gold) !important;
}

.bg-gray {
    background-color: var(--color-gray-dark) !important;
}

.bg-create {
    background-color: var(--color-snowy-mint) !important;
}

.text-primary {
    color: var(--color-tangaroa) !important;
}

.text-blue {
    color: var(--color-dodger-blue) !important;
}

.text-gray {
    color: var(--color-gray-dark) !important;
}

.text-orange {
    color: var(--color-orange) !important;
}

.text-rust {
    color: var(--color-sundown) !important;
}

.text-pink {
    color: var(--color-red);
}

.a-card-container {
    padding: 1rem;
    width: 100%;
}

.a-card-wrap {
    /* min-height: 650px; */
    border-radius: 0px 0px 10px 10px;
    border-top: 0px;
    min-height: 800px;
    max-height: 800px;
    overflow-y: auto;
    background-color: var(--color-white);
}

.a-card-wrap-skill-deatils {
    background-color: var(--color-gray);
}

.a-card-wrap-register {
    padding: 1rem;
    min-height: auto;
}


.a-card {
    /* width: fit-content; */
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    display: flex !important;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-gray);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.8rem;
    padding: 1rem;
    min-height: 710px;
    margin-bottom: 2rem;
}

.a-card-item {
    background: var(--color-white);
    border: 1px solid rgba(191, 199, 219, 0.3);
    border-radius: 10px;
    padding: 1rem;
}

.a-card-item-text {
    padding-left: 0.5rem;
    /* width: 200px; */
}

.a-card-item-right-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: var(--color-tangaroa);
    opacity: 0.4;
    padding-right: 0.5rem;
    width: auto;
}

.a-card .title {}

.a-card .sub-title {}

.icon {
    padding: 3px;
    width: 28px;
    height: 28px;
    background: var(--color-solitude);
    border-radius: 8px;
    margin-left: 10px;
}


.icon img,
.icon i {
    width: 100%;
}

.c-icon-box {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-right: 1em;
    background-color: var(--color-solitude);
    text-align: center;
    border-radius: 6px;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 1;
}

.c-icon-drop-box {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.c-icon-drop-box:hover {
    background-color: #f4f4f4;
}

.c-icon-drop-box.mailProcess {
    color: #0a253e;
}

.c-icon-drop-box i {
    margin-right: 8px;
}

.c-icon-box:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    /* margin-right: -0.25em; */
}

.c-icon-box:hover {
    background-color: var(--color-solitude-dark);
    cursor: pointer;
}

.c-icon-box i {
    color: var(--color-tangaroa);
    /* font-size: 2em; */
    margin: auto;
    text-align: center;
    vertical-align: middle;
}




.end-r-3 {
    right: 1rem !important;
}





/* Time Line  */

.tracking-detail {
    padding: 3rem 0
}

#tracking {
    margin-bottom: 1rem
}

[className*=tracking-status-] p {
    margin: 0;
    font-size: 1.1rem;
    color: var(--color-white);
    text-transform: uppercase;
    text-align: center
}

[className*=tracking-status-] {
    padding: 1.6rem 0
}

.tracking-status-intransit {
    background-color: #65aee0
}

.tracking-status-outfordelivery {
    background-color: #f5a551
}

.tracking-status-deliveryoffice {
    background-color: #f7dc6f
}

.tracking-status-delivered {
    background-color: #4cbb87
}

.tracking-status-attemptfail {
    background-color: #b789c7
}

.tracking-status-error,
.tracking-status-exception {
    background-color: #d26759
}

.tracking-status-expired {
    background-color: #616e7d
}

.tracking-status-pending {
    background-color: #ccc
}

.tracking-status-inforeceived {
    background-color: #214977
}

.tracking-list {
    /* border:1px solid #e5e5e5 */
}

.tracking-item {
    border-left: 1px dashed var(--color-solitude-dark);
    position: relative;
    padding: 0rem 1rem 1.5rem 2.5rem;
    font-size: .9rem;
    margin-left: 2rem;
    min-height: 6rem
}

.tracking-item:last-child {
    padding-bottom: 2rem;
    border-left: 0px solid #e5e5e5;
}

.tracking-item .tracking-date {
    margin-bottom: .5rem
}

.tracking-item .tracking-date span {
    font-size: 85%;
    padding-left: .4rem
}

.tracking-item .tracking-content {
    padding: .5rem .8rem;
    background-color: var(--color-white);
    border-radius: .5rem
}

.tracking-content p {
    margin-bottom: 0.1rem
}



.tracking-item .tracking-icon {
    /* padding: 0.2rem; */
    line-height: 2.6rem;
    position: absolute;
    left: -1.3rem;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    background-color: var(--color-white);
    color: var(--color-white)
}

.tracking-item .tracking-icon img {
    width: 17px;
    height: 17px;
}

.tracking-item .tracking-icon.status-sponsored {
    background-color: #f68
}

.tracking-item .tracking-icon.status-delivered {
    background-color: #4cbb87
}

.tracking-item .tracking-icon.status-outfordelivery {
    background-color: #f5a551
}

.tracking-item .tracking-icon.status-deliveryoffice {
    background-color: #f7dc6f
}

.tracking-item .tracking-icon.status-attemptfail {
    background-color: #b789c7
}

.tracking-item .tracking-icon.status-exception {
    background-color: #d26759
}

.tracking-item .tracking-icon.status-inforeceived {
    background-color: #214977
}

.tracking-item .tracking-icon.status-intransit {
    /* color:#e5e5e5;
    border:1px solid #e5e5e5; */
    /* font-size:.6rem */
}

@media(min-width:992px) {
    .tracking-item {
        /* margin-left:10rem */
    }

    .tracking-item .tracking-date {
        position: absolute;
        left: -10rem;
        width: 7.5rem;
        text-align: right
    }

    .tracking-item .tracking-date span {
        display: block
    }

    .tracking-item .tracking-content {
        padding: 0;
        background-color: transparent
    }
}

/* End Time Line  */

.file-card {
    background: var(--color-solitude);
    opacity: 0.8;
    border: 11px solid var(--color-white);
    border-radius: 1.25rem;
    left: 20px;
}

.file-card::before {
    content: " ";
    border-left: 1px dashed var(--color-solitude);
    border-bottom: 1px dashed var(--color-solitude);
    width: 20px;
    /* margin-left: -20px;  */

    content: '';
    position: absolute;
    top: -10px;
    left: -20px;
    margin-left: -1px;
    height: 80%;
    /* background: pink; */
    z-index: 1
}

.file-card-icon {
    border-radius: 50%;
    color: var(--color-white);
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: auto 3px;
    width: 40px;
    font-size: 18px;
    text-align: center;
}

.file-card-delete {
    padding: 0.2rem 0.4rem;
    background: var(--color-white);
    box-shadow: 0px 4px 14px rgba(207, 213, 226, 0.5);
    border-radius: 4px;
    width: fit-content;
}


.data-not-found-container {
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 50% !important;
    position: absolute !important;
}

.data-not-found-container-100 {
    width: 100%;
}

.data-not-found {
    /* width: 470px; */
    text-align: center;
}

.data-not-found .title {
    font-weight: 300;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: var(--color-tangaroa);
}

.data-not-found .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.right-content {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: auto;
    width: 100%;
}


.invisible-card {
    visibility: hidden;
    /* padding: 0 10px; */
    /* border-width: 0 1px; */
    margin-top: 0px;
    margin-bottom: 0px;
}



/* Modal */
.c-modal {
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 50% !important;
    position: absolute !important;
}

.c-modal-container {
    width: 500px;
    background: var(--color-white);
    box-shadow: 0px 0px 20px 3px rgb(177 177 177 / 50%);
    border-radius: 10px;
}

.c-modal-body {
    padding: 1.5rem;
}

.c-modal-footer {
    padding: 1.5rem;
    /* display: flex;
    align-items: center;
    justify-content: flex-end; */
    border-top: 1px solid var(--color-solitude);
    ;
}

.c-modal h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-tangaroa);
}

.c-modal p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.c-modal-body img {
    margin: auto;
    display: block;
    width: 200px;
}

.c-modal .c-transparent-btn {
    display: flex;
    flex-direction: column;
    background: none;
    border: none;
    align-items: center;
}

.c-modal .c-transparent-btn img {
    width: 25px;
}






.job-card-container {}


.job-order-card {
    position: relative;
    margin: 1rem;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: rgb(224 229 238) 0px 7px 0px;
    overflow: hidden;
    min-width: 790px;
    flex: 1 1;
}

.job-order-card:hover {
    /* box-shadow: 0px 1px 9px 0px #dddedf; */
    box-shadow: rgb(224 229 238) 0px 7px 4px 0px;

    /* margin-top:0.75rem; */
    /* margin-bottom:1.25rem; */
}

.job-order-card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.job-order-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 150px;
}

.job-order-card-body p {
    font-size: 13px;
    margin: 0 0 40px;
}

.job-order-card-user {
    display: flex;
    /*margin-top: auto;*/
}

.job-order-card-user-img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.job-order-card-user-info {
    /* margin-right: 15px; */
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: var(--color-alice-blue-100);
    padding: 1rem;
    height: 78px;
}

.job-order-card-user-info-col-150 {
    min-width: 150px;
}

.job-order-card-user-info-col-250 {
    min-width: 250px;
}

.job-order-card-user-info h5 {
    margin: 0;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
}

.job-order-card-user-info small {
    color: var(--color-tangaroa);
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}

.job-order-card-user-details {
    display: flex;
    flex-direction: column;
}

.job-order-card-user-name {
    padding-left: 1rem;
    font-weight: 500;
    font-size: 20px;
    color: var(--color-tangaroa);
}

.job-order-card-user-location {
    display: inline-flex;
    padding-left: 1rem;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.job-order-card-user-locatio-text {
    font-size: 12px;
    padding-right: 1rem;
}

.job-order-card-user-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}


.job-order-card-photo {
    width: 60px;
    height: 60px;
    padding: 2px;
    background-color: var(--color-white);
    border: 1px solid #F8E3FB;
    border-radius: 10px;
}

.job-order-card-photo-box {
    height: 100%;
    background: #F8E3FB;
    border-radius: 8px;
}

.job-order-card-photo-box img {
    width: 100%;
}

.job-order-card-photo-box-text {
    height: 100%;
    display: -ms-flexbox;
    display: flex;

    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-tangaroa);
}

.avatar-box {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-tangaroa);
}

.job-order-card .status {
    /* box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center; */
    padding: 6px 20px;
    gap: 10px;
    position: absolute;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-tangaroa);
    background: #c5c5c5;
}


.job-order-result-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-tangaroa);
}

.job-order-result-sub-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}


.job-order-horizontal-card {
    flex: 1 0;
    /* padding-bottom: 0.5rem;
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem; */
    margin: 10px;
    min-width: 1475px;
    /* max-width: 2000px; */
}

.job-order-horizontal-card-container:hover {
    /* box-shadow: 0px 1px 9px 0px #dddedf; */
    box-shadow: rgb(224 229 238) 0px 7px 4px 0px;
}

.job-order-horizontal-card-container {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    box-shadow: rgb(224 229 238) 0px 7px 0px;
    position: relative;
}

.job-order-horizontal-card-container .status {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 20px;
    gap: 10px;

    position: absolute;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 10px;

    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-tangaroa);
    background: #c5c5c5;
    /* width: 103px; */
}

.job-order-horizontal-card-body {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.job-order-horizontal-card-profile {
    width: 75px;
    aspect-ratio: 1/1;
    padding: 3px;
    background-color: var(--color-white);
    border: 1px solid #F8E3FB;
    border-radius: 22px;
}

.job-order-horizontal-card-profile-box {
    height: 100%;
    background: #F8E3FB;
    border-radius: 19px;
}

.job-order-horizontal-card-profile-box-img {
    width: 100%;
    height: 100%;
    border-radius: 22px;
}

.job-order-horizontal-card-profile-box-text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-tangaroa);
}

.job-order-horizontal-card-body .job {
    display: flex;
    align-items: center;
    /* gap: 1rem; */
}

/* .job-order-horizontal-card-body .status {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: var(--color-tangaroa);
} */

.job-order-horizontal-card-body .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    align-self: center;
    margin-right: 10px;
    background-color: var(--color-aero-green-light);
}

.job-order-horizontal-card-body .job .title {
    padding-left: 1rem;
}

.job-order-horizontal-card-body .job h1 {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-tangaroa);
}

.job-order-horizontal-card-body .job p {
    font-weight: 500;
    font-size: 12px;
    color: var(--color-tangaroa);
    opacity: 0.6;
    margin-bottom: 0;
}

.job-order-horizontal-card-body .job p img {
    padding-right: 10px;
}

.job-order-horizontal-card-body .group-detai-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.job-order-horizontal-card-body .group-detai-container-line {
    content: '';
    height: 71px;
    position: absolute;
    box-shadow: 0px 0px 1px 1px #f5f5f5;
}

.job-order-horizontal-card-body .group-info {
    display: flex;
    padding: 1rem;
}

.job-order-horizontal-card-body .group-info::before {
    /* background-color: red;
   box-shadow: var(--color-tangaroa);
   content: 'Hello'; */
}

.job-order-horizontal-card-body .group-info-fill {
    border-radius: 8px;
    background-color: var(--color-alice-blue-100);
}


.job-order-horizontal-card-body .group-info .group-info-icon {
    width: 25px;
}

.job-order-horizontal-card-body .group-info .label {
    margin: 0;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.job-order-horizontal-card-body .group-info .group-detail {
    padding-left: 1rem;
}

.job-order-horizontal-card-body .group-info .detail {
    color: var(--color-tangaroa);
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    padding-top: 2px;
    ;
}

.c-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.c-label.bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--color-tangaroa);
    opacity: 1;
}

.c-form-control {
    color: var(--color-tangaroa);
    background: var(--color-white);
    border: 1px solid #C1CEDA;
    border-radius: 4px;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
}

.c-form-control:focus {
    outline-color: #a9b6c1;
}

.c-input-group {
    /* min-width: 400px; */
    /* padding: 1rem; */
    margin: auto;
}

.c-line {
    border: 1px dashed #D3D9E7;
    width: 100% !important;
}

.a-card-details {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}


.c-table th {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
    align-self: center;
    vertical-align: middle;
}

.c-table td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
    vertical-align: middle;
}

.c-table-result {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
}




.c-pagination {
    margin: 0;
    padding: 0;
    text-align: center
}

.c-pagination li {
    display: inline;
    padding-left: 10px;
}

.c-pagination li a {
    display: inline-block;
    text-decoration: none;
    color: var(--color-tangaroa);
    border-radius: 3.79412px;
    padding: 5px 13px;
}

.c-pagination li a {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border: 1px solid var(--color-solitude);
}

.c-pagination li a.active {
    color: var(--color-white);
    background: var(--color-charlotte);
    border-radius: 3.79412px;
    border: 1px solid var(--color-charlotte);
}

.c-pagination li a:hover:not(.active) {
    background-color: var(--color-solitude-dark);
}

.c-badge {
    background: #9e9fa0;
    border-radius: 25px;
    color: var(--color-white);
    text-align: center;
    max-width: max-content;
    padding: 8px;
    padding-left: 22px;
    padding-right: 22px;
}

.c-badge-green {
    background-color: var(--color-white);
    color: var(--color-persian-green);
    border: 1px solid var(--color-persian-green);
}

.c-circle {
    width: 40px;
    height: 40px;
    background-color: var(--color-solitude-dark);
    border-radius: 50%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.c-circle {
    width: 40px;
    height: 40px;
    background-color: var(--color-solitude-dark);
    border-radius: 50%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}


/* .c-circle__content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
} */

.fill-order-card {
    border: 1px solid #d8e0e9;
    background-color: var(--color-gray-light);
    border-radius: 10px;
}

.fill-order-sub-card {
    background-color: var(--color-white);
    border-bottom: 1px dashed #d6dee8;
}

.fill-order-sub-card {
    background-color: var(--color-white);
    border-bottom: 1px dashed #d6dee8;
}

.fill-order-sub-card:last-child {
    border-bottom: none;
    margin-bottom: 1rem;
    box-shadow: 0px 2.81818px 9.86364px rgba(205, 212, 226, 0.4);
}

.fill-order-sub-card .profile-photo {
    width: 50px;
}

.fill-order-sub-card .people-name {
    font-weight: 500;
    color: var(--color-tangaroa);
    font-size: 1rem;
    align-self: center;
}

.fill-order-card .label {
    font-weight: 500;
    color: var(--color-tangaroa);
    font-size: 0.80rem;
    opacity: 0.6;
}

.fill-order-card .label-bold {
    font-weight: 500;
    font-size: 0.85rem;
    color: var(--color-tangaroa);
}

.rectangle-wrapper {
    border: 0.704545px solid #E0E5EE;
}


.job-people-card {
    flex: 1 0;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    /* min-width: 1675px;
    max-width: 2000px; */
}

.job-people-card-container {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(205, 212, 226, 0.4);
    min-width: 400px;
    max-width: 450px
}

.job-people-card-body {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px dashed #DEE4F1;

}

.job-people-card-profile {
    width: 60px;
    height: 60px;
    /* aspect-ratio: 1/1; */
    padding: 3px;
    background-color: var(--color-white);
    border: 1px solid #F8E3FB;
    border-radius: 18px;
}

.job-people-card-profile-box {
    height: 100%;
    background: #F8E3FB;
    border-radius: 18px;
}

.job-people-card-profile-box-img {
    width: 100%;
}

.job-people-card-body .people-name {
    font-weight: 500;
    font-size: 22px;
    color: var(--color-tangaroa);
}

.job-people-card-body .people-sub-title {
    font-weight: 500;
    font-size: 13px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.job-people-card-body .badge {
    height: fit-content;
    background: var(--color-solitude);
    border-radius: 30px;
    color: var(--color-tangaroa);
    text-align: center;
    max-width: max-content;
    padding: 8px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 1rem;
}

.job-people-card-footer {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.job-people-card-footer .label {
    font-weight: 500;
    color: var(--color-tangaroa);
    font-size: 0.80rem;
    opacity: 0.6;
}

.job-people-card-footer .label-bold {
    font-weight: 500;
    font-size: 0.85rem;
    color: var(--color-tangaroa);
}



.multi-card-container {
    display: flex;
    flex-wrap: wrap;
}

.card-bottom-line {
    width: auto;
    padding: 1rem;
}

.card-bottom-line-container {
    background: var(--color-white);
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    width: 100%;
    height: 100%;
    letter-spacing: .5px;
    font-weight: 500;
    -webkit-box-shadow: 0 4px 19px hsl(0deg 0% 93% / 90%);
    box-shadow: 0 4px 19px hsl(0deg 0% 93% / 90%);
    border-bottom: 3px solid;
    border-color: var(--color-amethyst);
}

.card-bottom-line .title {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: var(--color-tangaroa);
}

.card-bottom-line .badge {
    background: #9e9fa0;
    border-radius: 4px;
    color: var(--color-white);
    text-align: center;
    max-width: max-content;
    padding: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: inherit;
}

.avatar {
    width: 60px;
    height: 60px;
    /* width: 40px; */
    /* height: 40px; */
    aspect-ratio: 1/1;
    padding: 2px;
    background-color: var(--color-white);
    border: 1px solid #F8E3FB;
    border-radius: 18px;
    /* border-radius: 11px; */
}

.avatar-box {
    height: 100%;
    background: #F8E3FB;
    border-radius: 18px;
    /* border-radius: 8px; */
}

.avatar-box-img {
    border-radius: 18px;
    /* border-radius: 11px; */
    width: 100%;
    height: 100%;
}

.avatar-box-text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-tangaroa);
}

.c-horizontal-card {
    flex: 1 0;
    /* min-width: 1675px; */
    min-width: 760px;
    max-width: 2000px;
}

.c-horizontal-card-container {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 10px;
}

.c-horizontal-card-body {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.c-horizontal-card .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: var(--color-tangaroa);
}

.c-horizontal-card .sub-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.c-horizontal-card .badge {
    border-radius: 7px;
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--color-tangaroa);
    background: var(--color-aero-green-light);
}

.c-horizontal-card .badge-transparent {
    /* border-radius: 7px; */
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--color-tangaroa);
    opacity: 0.6;
    /* background: var(--color-aero-green-light); */
}


/* Group Avtar */

.g-avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background-color: var(--color-solitude);
    /* border: 2px solid #2c303a; */
}

.g-avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.g-avatar-group {
    display: flex;
    margin-left: 10px;
}

.g-avatar-group.rtl {
    direction: rtl;
}

.g-avatar-group.rtl .avatar:hover:not(:last-of-type) {
    transform: translate(10px);
}

.g-avatar-group .g-avatar {
    margin-left: -10px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.g-avatar-group .g-avatar:hover:not(:last-of-type) {
    transform: translate(-10px);
}

.g-avatar-group .g-hidden-avatars {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-solitude);
    color: var(--color-solitude-dark);
    font-size: 14px;
}

/* End Group Avtar */



/* input */

input[type="date"] {
    position: relative;
    /* padding: 10px; */
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="date"]:before {
    /* color: transparent; */
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    /* This is the calendar icon in FontAwesome */
    /* width: 15px; */
    /* height: 20px; */
    position: absolute;
    /* top: 12px; */
    right: 20px;
    /* color: inherit; */
}

input[type],
.form-select {
    font-weight: 500;
    color: var(--color-tangaroa);
}

select {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.label-box {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

/* input */




.separator {
    display: flex;
    align-items: center;
}

.separator .line {
    /*   height: 3px; */
    border-bottom: 1px dashed var(--color-solitude-dark);
    /*   border-style: dashed; */
    flex: 1;
    /*   background-color: #000; */
}

.separator .line-start {
    max-width: 1.75rem;
}

.separator h2 {
    padding: 0 2rem;
}

.separator-input {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.separator-input:focus {
    outline: none !important;
}

.separator-input.style-2 {
    background: #EDF1FA;
    color: var(--color-tangaroa);
    font-weight: 500;
}

input:disabled {
    background: rgba(193, 206, 218, 0.28) !important;
}

select:disabled {
    background: rgba(193, 206, 218, 0.28) !important;
}


/* flyout */


@keyframes move-right-to-left-style-2 {

    /* from {
        left: 400px;
    }
    to {
        left: 0px;
    } */
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes move-left-to-right-style-2 {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

.flyout-card.open {
    animation: move-right-to-left-style-2 1.5s;
}

.flyout-card.close {
    animation: move-left-to-right-style-2 1.5s;
}

.flyout-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.flyout-backdrop.flyout-backdrop-show {
    opacity: 0.5;
}

.flyout-wrapper {
    /* z-index: 1001; */
    z-index: 1041;
    /* margin-top: 61px; */
    /* max-width: 430px; */
    /* min-width: 400px; */
    width: 480px;
}

.flyout-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-white);
    background-clip: border-box;
    border-radius: 0.25rem;
    background: var(--color-white);
    box-shadow: -13px 0px 15px rgba(229, 233, 241, 0.7);
    /* height: calc(100vh - 63.5px); */
    height: 100vh;
}

.flyout-card-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--color-solitude);
    /* box-shadow: 0px 1px 0px var(--color-solitude); */
}

.flyout-card-body {
    padding: 1rem 1.5rem;
    overflow: auto;
}

.flyout-card-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: var(--color-tangaroa);
}

.flyout-card-sub-title {
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.flyout-card-sub-title.bold {
    font-weight: 500;
    color: var(--color-tangaroa);
    opacity: 1;
}

.flyout-card-semi-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
}

.flyout-card-semi-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: var(--color-tangaroa);
}

.flyout-card-footer {
    bottom: 0;
    right: 0;
    padding: 1rem 1.5rem;
}

.flyout-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.flyout-label-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 1;
}

.flyout-label-radio {
    padding-left: 0.5rem;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: var(--color-tangaroa);
}

.flyout-label-radio:active {
    font-weight: 800;
}

/* .flyout-card-body .form-group {
    display: block;
    margin-bottom: 15px;
}

.flyout-card-body .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.flyout-card-body .form-group label {
    position: relative;
    cursor: pointer;
}

.flyout-card-body .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #D4DBEA;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.flyout-card-body .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid var(--color-tangaroa);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */

.flyout-card-filter-bar {
    padding: 1rem 1.5rem;
    background-color: #F5F7FB;
}

.flyout-card-load-more-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-dodger-blue) !important;
}

.flyout-sort-bt {
    font-weight: 500;
    background: var(--color-white);
    border: 1px solid #EDF1FA;
    border-radius: 5px;
}

.flyout-list-group li {
    display: block;
}



.flyout-list-group-item-card-container {
    border: 1px solid rgba(191, 199, 219, 0.3);
    border-radius: 10px;
    overflow: auto;
}

.flyout-list-group-item-card {
    display: flex;
    align-items: center;
    background: var(--color-white);
    padding: 1rem;
}


.flyout-list-group-item-card.active {
    background-color: var(--color-alice-blue) !important;
}

.flyout-list-group-item-card.system {
    background-color: var(--color-pattens-blue) !important;
}

.flyout-list-group-item-card .icon-img {
    width: 26px;
    height: 26px;
}

.flyout-list-group-item-card .sub-icon {
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding-right: 10px; */
    /* padding-left: 10px; */
    width: 48px;
    height: 48px;
    background: #fff3f7;
    border-radius: 10px;
}

.flyout-list-group-item-card .sub-icon img {
    width: 100%;
}

.flyout-list-group-item-card .file-size-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: var(--color-tangaroa);
    opacity: 0.5;
    margin-bottom: 0.1rem;
    text-align: center;
}

.flyout-list-group-item-card .icon-btn {
    width: 18px;
    height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.flyout-list-group-item-card .icon-btn:hover {
    color: var(--color-tangaroa);
    opacity: 1;
}

.flyout-list-group-item-card .bold-text {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-tangaroa);
}

.flyout-list-group-item-card .light-text {
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: var(--color-tangaroa);
    opacity: 0.4;
    padding-right: 0.5rem;
    width: auto;
}

.flyout-list-group-item-card-footer {
    background: var(--color-white);
    /* display: flex;
    align-items: center;
    padding: 1rem; */
}

.flyout-list-group-item-card .file-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-tangaroa);
}

.flyout-list-group-item-card .file-by {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.4;
}



.flyout-list-group-item-card-profile-img {
    width: 32px;
    height: 32px;
    border-radius: 10px;
}

.flyout-message-container {
    /* border: 1px solid rgba(191, 199, 219, 0.3); */
    border-radius: 10px;
    /* overflow: auto; */
}

.flyout-message-card {
    display: flex;
    -ms-flex-align: center;
    /* align-items: center; */
    background: var(--color-white);
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
}

.flyout-message-card .received {
    margin-left: 12px;
    max-width: 230px;
    background: #e9f8f9;
    padding: 0.5rem 1rem;
    -webkit-border-radius: 0px 10px 10px 10px;
    -moz-border-radius: 0px 10px 10px 10px;
    border-radius: 0px 10px 10px 10px;
}

.flyout-message-card .replied {
    margin-right: 12px;
    max-width: 230px;
    background: #e9f3fc;
    padding: 0.5rem 1rem;
    -webkit-border-radius: 10px 0px 10px 10px;
    -moz-border-radius: 10px 0px 10px 10px;
    border-radius: 10px 0px 10px 10px;
}

.flyout-message-card .message-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: var(--color-tangaroa);
}

.flyout-message-card .message-date {
    font-weight: 400;
    font-size: 10px;
    padding-top: 0.35rem;
    color: var(--color-tangaroa);
    opacity: 0.4;
}

.flyout-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 14px;
    gap: 15px;
    /* height: 180px; */
    background: var(--color-white);
    border: 1px solid rgb(230, 234, 243);
    box-shadow: rgb(224 229 238) 0px 2px 0px;
    border-radius: 4px;
    width: 100%;
}

.flyout-input:focus {
    outline: none !important;
}

.flyout-input-group {
    padding: 10px 20px;
    background: var(--color-white);
    border-radius: 8px;
}

.flyout-message-send-btn {
    border: none;
    background: none;
}

.flyout-message-text-box {
    background: var(--color-gray);
    border-radius: 10px;
    height: 52px;
    border: none;
    padding: 1rem;
    width: calc(100% - 52px);
}

.flyout-message-text-box:focus {
    outline: none !important;
}

.flyout-message-separator {
    display: flex;
    align-items: center;
}



.flyout-data-not-found-container {
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 50% !important;
    position: absolute !important;
}

.flyout-data-not-found {
    width: 320px;
    text-align: center;
}

.flyout-data-not-found .title {
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 26px;
    text-align: center;
    color: var(--color-tangaroa);
}

.flyout-data-not-found .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}


.flyout-separator {
    display: flex;
    align-items: center;
}

.flyout-separator .line {
    border-bottom: 1px solid var(--color-solitude);
    flex: 1;
}

.flyout-separator .unread-messages {
    font-size: 10px;
    font-weight: 400;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 13px;
    left: 1614px;
    top: 753px;
    background: var(--color-solitude);
    color: var(--color-tangaroa);
    border-radius: 30px;
}


/* End flyout */

.sort-btn-group {
    border: 1px solid #E5E9F1;
    border-radius: 5px;
}

.sort-btn-transparent {
    background-color: rgba(255, 255, 255, 0) !important;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-tangaroa);
}

.sort-btn-active {
    padding: 8px 14px;
    box-shadow: 0px 4px 4px #e5e9f1;
}






.filter-input {
    margin-right: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 12px 14px; */
    gap: 15px;
    /* height: 180px; */
    background: var(--color-white);
    border: 1px solid rgb(230, 234, 243);
    box-shadow: rgb(224 229 238) 0px 2px 0px;
    border-radius: 4px;
    width: 200px;
    height: 36px;
}

.filter-input:focus {
    outline: none !important;
}



.switch-container-content {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid var(--color-solitude-dark);
    border-left-width: .25rem;
    border-radius: .25rem;
    border-left-color: var(--color-charlotte);
}

.switch-container {
    padding: 0 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mind-map-target-hide {
    display: none;
}

.switch {
    position: relative;
    display: flex;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.switch-label {
    margin-right: 0.5rem !important;
    cursor: pointer;
}



.multi-selector {
    position: relative !important;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px;
}

.multi-selector-options-container {
    position: absolute;
    top: 100%;
    z-index: 10000;
    float: left;
    min-width: 15rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: var(--color-tangaroa);
    text-align: left;
    list-style: none;
    background-color: var(--color-white);
    background-clip: padding-box;
    box-shadow: 0px 2px 42px 0px rgb(0 0 0 / 10%);
    border-radius: 0.25rem;
    width: 100%;
}

.multi-selector-options {
    margin: 0;
    padding-left: 0rem;
    max-height: 20rem;
    overflow-y: auto;
}

.multi-selector-options .multi-selector-option {
    list-style-type: none;
    transition: 0.2s;
    padding: 0.3rem 0.9rem;
    display: flex;
}

.multi-selector-options .multi-selector-option:hover {
    color: var(--color-tangaroa);
    font-weight: 700;
    background-color: var(--color-solitude-dark);
    cursor: pointer !important;
    border-radius: 0.25rem;
}

.form-check-input {
    cursor: pointer !important;
}

.option-check-label {
    cursor: pointer !important;
    margin-left: 1rem;
    margin-bottom: 0;
}

.selected-option {
    padding: 0.5rem 0.5rem;
    color: var(--color-white);
    background-color: var(--color-charlotte);
    border-radius: 0.25rem;
    margin: 0.3rem;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
}

.selected-options-container {
    display: flex;
    flex-direction: row;
    padding: 0.3rem;
    /* box-shadow: inset 0 0 3px #aaaaaa; */
    border-radius: 0.25rem;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    cursor: pointer;
    min-height: 2.5rem;
    background-color: var(--color-white);
}

.option-selected {
    color: var(--color-tangaroa);
    font-weight: 700;
}

.selected-options-summary {
    background-color: var(--color-solitude-dark);
    padding: 0 0.3rem;
    border-radius: 0.3rem;
    margin: 0.2rem 0.5rem;
}

.selector-disabled {
    cursor: not-allowed !important;
}





.auto-complete {
    position: relative !important;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.auto-complete-options-container {
    position: absolute;
    top: 100%;
    z-index: 10000;
    float: left;
    min-width: 15rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: var(--color-tangaroa);
    text-align: left;
    list-style: none;
    background-color: var(--color-white);
    background-clip: padding-box;
    box-shadow: 0px 12px 25px 0px rgb(0 0 0 / 20%);
    border-radius: 0.25rem;
    width: 100%;
    outline: none ! important;
}

.auto-complete-options {
    margin: 0;
    padding-left: 0rem;
    max-height: 20rem;
    overflow-y: auto;
}

.auto-complete-options .auto-complete-option {
    list-style-type: none;
    transition: 0.2s;
    padding: 0.3rem 0.9rem;
    display: flex;
}

.auto-complete-options .auto-complete-option:hover {
    color: var(--color-tangaroa);
    font-weight: 700;
    background-color: var(--color-solitude-dark);
    cursor: pointer !important;
    border-radius: 0.25rem;
}




.time-input {
    display: flex;
    flex: 1;
}

.time-input .control {
    width: 80%;
}

.time-input .control-24HR {
    width: auto;
    flex: 1;
}

.time-input .format-selector {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    background-color: var(--color-solitude-dark);
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.time-input .format-selector .shifter {
    cursor: pointer;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.time-input .format-selector .shifter:hover {
    color: var(--color-tangaroa);
    opacity: 1 !important;
}



.time-input .format-selector .shifter-value {
    color: var(--color-tangaroa);
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.z-index-1 {
    z-index: 1 !important;
    /* background-color: red; */
}

.z-index-99999 {
    z-index: 99999 !important;
}

.float-button {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    bottom: 30px;
    right: 60px;
    /* width: 150px; */
    /* font-size: 20px; */
    /* text-align: center;
    vertical-align: middle; */
}




.file-upload-box {
    background: linear-gradient(180deg, rgba(236, 239, 246, 0.1) 0%, #F5F7FB 163.22%);
    border: 1px dashed #BFC7DB;
}

.file-upload-box-invalid {
    border: 1px dashed var(--color-red);

}

.file-upload-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height */


    color: var(--color-tangaroa);
}


.c-tooltip {
    font-size: 10px !important;
    pointer-events: auto !important;
    padding: 11px !important;
    line-height: 7px;
    /* border-radius: 10px !important; */
    background: var(--color-tangaroa) !important;
    pointer-events: auto !important;
}

.c-tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;

}

.f-w-500 {
    font-weight: 500;
}

.link {
    color: var(--color-white);
    /* text-transform: uppercase; */
    text-decoration: none;
    /* letter-spacing: 0.15em; */
    display: inline-block;
    /* padding: 15px 20px; */
    position: relative;
}

.link:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #364f62;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.link:hover:after {
    width: 100%;
    left: 0;
}



/* modal */


.modal {
    display: block !important;
    opacity: 1;
}

.modal-content {
    /* box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12) !important; */
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden;
    /* box-shadow: -13px 0px 15px rgba(177, 177, 177, 0.5) !important; */
    box-shadow: 0px 0px 20px 3px rgb(177 177 177 / 50%) !important;
}

.modal-close-button {
    padding: 0;
    /* background-color: transparent; */
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    color: var(--color-tangaroa) !important;

    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: var(--color-white);
    box-shadow: 0px 4px 14px rgb(207 213 226 / 50%);
}

.modal-close-button:hover {
    background-color: var(--color-gray-dark);
}

.modal-body {
    /* box-shadow: inset 0 0 15px #000000 !important; */
    padding: 1.5rem !important;
}

.modal-header {
    background-color: var(--color-white) !important;
    color: var(--color-tangaroa) !important;
    padding: 1.5rem !important;
}

.modal-footer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.modal-header-name {
    color: black !important;
}

.modal-photo {
    margin: auto;
    display: block;
    width: 200px;
    border-radius: 20px;
}

.modal .c-transparent-btn {
    display: flex;
    flex-direction: column;
    background: none;
    border: none;
    align-items: center;
}

.modal .c-transparent-btn img {
    width: 25px;
}

.modal-sub-title {
    color: var(--color-tangaroa);
    opacity: 0.6;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0.5rem !important;
    padding-right: 1.5rem !important;
}




.client-name-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    background-color: rgba(193, 206, 218, 0.28);
}

.client-label {
    padding: 0.5rem;
    background-color: #e9ecef;
    border-right: 1px solid #ced4da;
}

.client-name {
    margin-left: 1rem;
    cursor: text;
    flex: 1;
    font-weight: 600;
    color: #495057;
}

.optionRed {
    background-color: var(--color-red);
}



.DipatchDialogWidth {
    max-width: 1250px !important;
}

.FillOrderDialogWidth {
    max-width: 1600px !important;
    /* width: 90%; */
}

.dropdown-item-disabled {
    font-size: 16px;
    color: var(--color-red) !important;
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: var(--color-tangaroa);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item-disabled:hover {
    background-color: var(--color-gray) !important;
    color: var(--color-red) !important;
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: var(--color-tangaroa);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: not-allowed;
}

.barcode-container {
    display: flex !important;
    justify-content: center !important;
}

.barcode {
    width: 90% !important;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
    border-radius: 1rem;
}

.coming-soon {
    width: 100% !important;
}


.form-group {
    /* margin-bottom: 0.5rem; */
    /* padding: 1rem; */
}

.field-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--color-tangaroa);
    opacity: 0.6;
}


.field-label-value {
    display: block;
    padding: 0.3rem 0.5rem;
    border-radius: 0.35rem;
    min-height: 2rem;
    background-color: rgba(193, 206, 218, 0.28) !important;
    font-weight: 500;
    color: var(--color-tangaroa) !important;

    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
}




.sort-by-container {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center;

    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.sort-by {
    padding: 0 0.5rem;
    margin: 0 0.3rem;
    cursor: pointer;
}

.sort-by-menu {
    position: absolute;
    /* top: 2.5rem; */
    /* top: 2.5rem; */
    z-index: 10000;
    float: left;
    min-width: 11rem;
    padding: 0.5rem 1rem;
    color: var(--color-tangaroa);
    text-align: left;
    list-style: none;
    background-color: var(--color-white);
    background-clip: padding-box;
    box-shadow: 0px 12px 25px 0px rgb(0 0 0 / 20%);
    border-radius: 0.25rem;
    outline: none ! important;
    margin-top: 0.3rem;
}

.sort-by-menu-items {
    margin: 0;
    padding-left: 0rem;
    max-height: 20rem;
    overflow-y: auto;
}

.sort-by-menu-items .sort-by-menu-item {
    list-style-type: none;
    transition: 0.2s;
    padding: 0.3rem 0.3rem;
    display: flex;
    align-items: center;
}

.sort-by-menu-items .sort-by-menu-item:hover {
    color: var(--color-tangaroa);
    font-weight: 700;
    background-color: var(--color-solitude-dark);
    cursor: pointer !important;
    border-radius: 0.25rem;
}

.sort-button {
    cursor: pointer;
}


.setting-card {
    overflow: hidden;
    border-radius: 10px;
    min-height: 845px;
    max-height: 845px;
    /* min-width: 293px; */
    /* min-width: 330px; */
    z-index: 1;
}

.setting-card-menu {
    width: auto;
}

.setting-card-menu-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: var(--color-tangaroa);
    padding-left: 25px;
    padding-top: 25px;
}

.setting-card-menu a {
    background-color: var(--color-white);
    color: var(--color-tangaroa);
    display: block;
    padding: 12px 25px;
    text-decoration: none;
    font-size: 14px;
}

.setting-card-menu a:hover {
    background-color: var(--color-solitude-dark);
}

.setting-card-menu a.active {
    background-color: var(--color-solitude);
    color: var(--color-tangaroa);
    font-weight: 600;
}




/* Setting  */
.default-root-container {
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .125);
}

.detail-container {
    height: 100%;
    background-color: var(--color-white) !important;
}

.detail-container-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: var(--color-tangaroa);
}

.detail-container-light-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: var(--color-tangaroa);
    opacity: .6;
}

.detail-container-sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--color-tangaroa);
    opacity: .6;
}


.client-log-box {
    width: 65px;
    height: 65px;
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    box-shadow: 0px 2px 0px #E0E5EE;
    border-radius: 6px;
    padding: 10px;
}


.tool-bar {
    display: flex;
    background: var(--color-white);
    box-shadow: 0px 4px 6px rgb(206 213 225 / 30%);
}

.fa:hover {
    cursor: pointer;
}

.report-heading {}



/* ej2 Grid */
.e-grid {
    border: none !important;
}

.e-grid .e-gridheader {
    /* border: 2px solid green */
}

.e-grid .e-headercell {
    font-weight: 500;
    font-size: 14px;
    /* line-height: 21px; */
    color: var(--color-tangaroa);
}



.e-grid .e-icon-ascending::before {
    content: '\e306';
}

.e-grid .e-icon-ascending::before {
    content: '\e304';
}

.e-grid .e-toolbar {
    border-top: none !important;
}

.e-grid .e-toolbar-items {
    background-color: var(--color-white) !important;
}


.e-grid th {}

.e-grid .e-gridpager {
    padding-top: 1.5rem;
}

.e-grid .e-pagercontainer {
    border: none !important;
    border: 0px;
}

.e-grid .e-gridpager .e-prevpagedisabled,
.e-grid .e-gridpager .e-prevpage,
.e-grid .e-gridpager .e-nextpage,
.e-grid .e-gridpager .e-nextpagedisabled,
.e-grid .e-gridpager .e-lastpagedisabled,
.e-grid .e-gridpager .e-lastpage,
.e-grid .e-gridpager .e-firstpage,
.e-grid .e-gridpager .e-firstpagedisabled {
    border-radius: 3.79412px;
    transition: background-color 0.3s;
    border: 1px solid var(--color-solitude);
    /* margin-left: .5rem; */
    margin-right: 0.5rem !important;
}

.e-grid .e-gridpager .e-numericitem {
    border-radius: 3.79412px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    transition: background-color 0.3s;
    border: 1px solid var(--color-solitude);
    color: var(--color-tangaroa);
}


.e-grid .e-gridpager .e-currentitem {
    color: var(--color-white);
    background: var(--color-charlotte);
    border-radius: 3.79412px;
    border: 1px solid var(--color-charlotte);
}

.e-pager .e-currentitem.e-numericitem.e-focused {
    color: var(--color-white);
    background: var(--color-charlotte) !important;
    border-radius: 3.79412px;
    border: 1px solid var(--color-charlotte) !important;
    box-shadow: none !important;
}

/* ej2 Grid */


.file-upload {
    /* display: flex; */
    /* width: 100%; */
    height: calc(1.5em + 0.75rem + 2px);
    color: var(--color-tangaroa);
    background-color: var(--color-white);
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.file-upload span.choose-file {
    padding: 0.375rem 0.75rem;
    display: flex;
    background-color: var(--color-solitude-dark);
    color: var(--color-white);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.file-upload span.chosen {
    padding: 0.375rem 0.75rem;
    flex: 1;
}

.file-upload span.upload {
    padding: 0.375rem 0.75rem;
    justify-self: flex-end;
}

.file-upload-input {
    display: none !important;
}

.invalid-choose-file {
    background-color: var(--color-red) !important;
}

/* .applicant-photo-uploader {
    display: none !important;
} */


.pass-strip {
    display: -webkit-flex;
    display: flex;
    background-color: var(--color-chateau-green);
    color: white;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%) !important;
    cursor: pointer;
    padding: 0.3rem 0.5rem;
    border-radius: 2rem;
    margin-right: 1rem;
    -webkit-justify-content: space-around;
    /* justify-content: space-around; */
    /* width: 94%; */
    /* flex: 1; */
    align-items: center;
}

.pass-strip:hover {
    background-color: var(--color-persian-green);
    ;
}

.ng-strip {
    display: -webkit-flex;
    display: flex;
    background-color: var(--color-light-orange);
    color: var(--color-white);
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%) !important;
    cursor: pointer;
    padding: 0.3rem 0.5rem;
    border-radius: 2rem;
    margin-right: 1rem;
    -webkit-justify-content: space-around;
    /* justify-content: space-around; */
    /* width: 94%; */
    /* flex: 1; */
    align-items: center;
}

.ng-strip:hover {
    background-color: var(--color-orange);
    ;
}



/* .filter{
    position: relative !important;
    margin-right: 0.3rem;
    display: inline-flex;
} */
.filter {
    position: relative !important;
    margin-right: 0.3rem;
    display: inline-flex;
    margin-right: 1rem;
    box-sizing: border-box;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 14px;
    grid-gap: 15px;
    gap: 15px;
    background: var(--color-white);
    border: 1px solid rgb(230, 234, 243);
    box-shadow: rgb(224 229 238) 0px 2px 0px;
    border-radius: 4px;
    height: 36px;
}

.filter-control-container {
    position: absolute;
    top: 2rem;
    left: 0;
    z-index: 10000;
    float: left;
    min-width: 15rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: var(--color-tangaroa);
    text-align: left;
    list-style: none;
    background-color: var(--color-white);
    background-clip: padding-box;
    box-shadow: 0px 12px 25px 0px rgb(0 0 0 / 20%);
    border-radius: 0.25rem;
    outline: none ! important;
}

.filter-list {
    margin: 0;
    padding-left: 0rem;
    max-height: 20rem;
    overflow-y: auto;
}

.filter-list .filter-list-item {
    list-style-type: none;
    transition: 0.2s;
    padding: 0.3rem 0.3rem;
    display: flex;
    align-items: center;
    font-size: small;
}

.filter-list .filter-list-item:hover {
    color: var(--color-persian-green);
    font-weight: 700;
    background-color: var(--color-solitude-dark);
    cursor: pointer !important;
    border-radius: 0.25rem;
}

.form-check-input {
    cursor: pointer !important;
}

.form-check-label {
    cursor: pointer !important;
}

.filter-selected {
    color: var(--color-persian-green);
    font-weight: 700;
}

.filter-value-selected {
    background-color: var(--color-solitude);
}

.filter-title {
    padding: 0.5rem;
    border-radius: 0.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    cursor: pointer;
}

.filter-title:hover {
    background-color: var(--color-solitude-dark);
}

.filter-value {
    cursor: pointer;
    margin-left: 0.5rem !important;
}


/* start jobDatefilter Css */
.filter-label {
    font-size: small !important;
    width: 100% !important;
}

.filter-controls {
    color: var(--color-tangaroa);
}

.filter-controls:hover {
    color: var(--color-tangaroa);
}

.close-filter-button {
    float: right !important;
    color: var(--color-tangaroa);
    cursor: pointer !important;
}

.close-filter-button:hover {
    color: var(--color-tangaroa) !important;
}

/* End jobDatefilter Css */



.DeletePhoto {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: initial;
    border: none;
}



/* Start Chat */

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

.zindexforsliding {
    z-index: 9999999;
}

.chatback {
    background-color: var(--color-tangaroa);
    border-radius: 4.5em;
}

.slidingChat {
    background-color: var(--color-white) !important;
    opacity: 0.9;
}


/* html,
body {
    margin: 0;
    padding: 0;
    background: #181b21 url(https://www.toptal.com/designers/subtlepatterns/patterns/nami.png);
    font-family: 'Quicksand', sans-serif;
    letter-spacing: -0.23px;
} */

.chatApp__loaderWrapper {
    margin: 80px auto;
    text-align: center;
}

.chatApp__loaderText {
    color: var(--color-dodger-blu);
    font-weight: bold;
}

.chatApp__loader,
.chatApp__loader::before,
.chatApp__loader::after {
    border-radius: 1em;
    width: 1em;
    height: 1em;
    animation-fill-mode: both;
    animation: loading 1.8s infinite ease-in-out;
}

.chatApp__loader {
    margin: auto;
    color: var(--color-dodger-blu);
    font-size: 12px;
    position: relative;
    animation-delay: -0.16s;
}

.chatApp__loader::before,
.chatApp__loader::after {
    content: '';
    position: absolute;
    top: 0;
}

.chatApp__loader::before {
    left: -1.5em;
    animation-delay: -0.32s;
}

.chatApp__loader::after {
    left: 1.5em;
}

.chatApp__room {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    /* padding: 5rem 20rem; */
    animation: fadeChatApp 0.6s ease-in-out;
    animation-fill-mode: forwards;
}

.chatApp__conv {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    float: left;
    /* margin: 0 2.5rem; */
    box-shadow: 0 35px 20px -30px rgba(255, 255, 255, 0.1);
}

.chatApp__convTitle {
    display: inline-block;
    margin: 0 0 2rem 0;
    font-size: 15px;
    font-weight: bold;
    color: var(--color-dodger-blu);
    /* text-transform: uppercase; */
    position: relative;
}

.chatApp__convTitle::after {
    content: '';
    position: absolute;
    background-color: var(--color-dodger-blu);
    width: 100%;
    height: 2px;
    border-radius: 30px;
    left: 0;
    right: 0;
    bottom: -16px;
}

.chatApp__convTimeline {
    display: flex;
    height: 800px;
    padding: 1rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--color-white);
    flex-direction: column-reverse;
    overflow-y: auto;
}

.chatApp__convMessageItem {
    padding: 1rem 0 0 0;
    opacity: 0;
    transition: all 0.15s ease-in-out;
    animation: fadeNewMessage 0.5s;
    animation-fill-mode: forwards;
}

.chatApp__convMessageAvatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    image-rendering: -webkit-optimize-contrast;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chatApp__convMessageValue {
    position: relative;
    max-width: 50%;
    min-height: 40px;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    /* font-weight: bold; */
    box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

.chatApp__convMessageItem--left .chatApp__convMessageAvatar {
    float: left;
}

.chatApp__convMessageItem--left .chatApp__convMessageValue {
    float: left;
    text-align: left;
    margin: 0 0 0 1rem;
    color: var(--color-white);
    background-color: var(--color-dodger-blu);
}

.chatApp__Date {
    color: rgb(138, 138, 138) !important;
}

.chatApp__convMessageItem--left .chatApp__convMessageValue::before {
    content: '';
    position: absolute;
    top: 14px;
    left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 10px 6px 0;
    border-color: transparent var(--color-dodger-blu) transparent transparent;
}

.chatApp__convMessageItem--right .chatApp__convMessageAvatar {
    float: right;
}

.chatApp__Date--left {
    color: var(--color-white) !important;
    font-size: 10px;
}

.chatApp__Date--right {
    color: black !important;
    font-size: 10px;
}

.chatApp__convMessageItem--right .chatApp__convMessageValue {
    float: right;
    text-align: right;
    margin: 0 1rem 0 0;
    background-color: var(--color-gray);
}

.chatApp__convMessageItem--right .chatApp__convMessageValue::after {
    content: '';
    position: absolute;
    top: 14px;
    right: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent var(--color-gray);
}

.chatApp__convMessageItem--left .chatApp__convMessageValue a {
    color: var(--color-chateau-green);
}

.chatApp__convMessageItem--left .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--left .chatApp__convMessageValue a:focus {
    color: var(--color-chateau-green);
}

.chatApp__convMessageItem--right .chatApp__convMessageValue a {
    color: var(--color-dodger-blue);
}

.chatApp__convMessageItem--right .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--right .chatApp__convMessageValue a:focus {
    color: var(--color-dodger-blue);
}

.chatApp__convSendMessage {
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--color-white);
}

.chatApp__convSendMessage {
    padding: 1rem;
}

.chatApp__convInput {
    float: left;
    height: 40px;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 40px;
    margin: 0 0.5rem 0 0;
    width: calc(100% - 52px);
    background-color: var(--color-gray);
    box-shadow: inset 0 0 0 2px var(--color-gray);
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
    /* font-weight: bold; */
    transition: 0.15s all ease-in-out;
    box-sizing: border-box;
}

.chatApp__convInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-gray);
    opacity: 1;
}

.chatApp__convInput:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-gray);
}

.chatApp__convInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-gray);
}

.chatApp__convInput:focus,
.chatApp__convInput:active {
    box-shadow: inset 0 0 0 2px var(--color-gray);
}

.chatApp__convInput:focus {
    outline: none;
}

.chatApp__convButton {
    float: right;
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 8px;
    border-radius: 30px;
    color: var(--color-white);
    background-color: var(--color-dodger-blu);
    text-align: center;
    box-shadow: 0px 14px 10px -8px rgba(0, 0, 0, 0.2);
    transition: 0.15s all ease-in-out;
    box-sizing: border-box;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chatApp__convButton:hover {
    background-color: var(--color-dodger-blue);
}

.chatApp__convButton i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 0 0 1px;
    font-size: 22px;
}

.chatApp__convButton--loading {
    cursor: wait;
    background-color: var(--color-dodger-blu);
}

.chatApp__convButton--loading:hover {
    background-color: var(--color-dodger-blu);
}

.chatApp__convButton--loading::before,
.chatApp__convButton--loading::after {
    content: '';
    position: absolute;
    z-index: 1;
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    margin: auto;
    border-radius: 40px;
    background-color: var(--color-white);
}

.chatApp__convButton--loading::after {
    animation: loadSendMessage 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

.chatApp__convButton--loading i {
    display: none;
}

.chatApp__convSendMessage--typing .chatApp__convTyping {
    display: block;
}

.chatApp__convTyping {
    position: absolute;
    top: 0;
    font-size: 10px;
    font-weight: bold;
}

.chatApp__convTypingDot {
    position: relative;
    left: 4px;
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: var(--color-teal-blue);
    animation: typingMessage 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.chatApp__convTypingDot::before,
.chatApp__convTypingDot::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: var(--color-teal-blue);
    animation: typingMessage 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.chatApp__convTypingDot::before {
    left: 4px;
    animation-delay: 0.5s;
}

.chatApp__convTypingDot::after {
    left: 8px;
    animation-delay: 1s;
}

@media screen and (max-width:768px) {
    .chatApp__room {
        flex-wrap: wrap;
        padding: 2rem 1rem;
    }

    .chatApp__conv {
        flex-basis: 100%;
        min-width: auto;
        width: 100%;
        margin: 0;
    }

    .chatApp__conv:first-of-type {
        margin: 0 0 4rem 0;
    }
}

@keyframes loading {

    0%,
    80%,
    100% {
        box-shadow: 0 1em 0 -1.3em;
    }

    40% {
        box-shadow: 0 1em 0 0;
    }
}

@keyframes fadeChatApp {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeNewMessage {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes loadSendMessage {
    0% {
        opacity: 1;
        width: 4px;
        height: 4px;
    }

    100% {
        opacity: 0;
        width: 100%;
        height: 100%;
    }
}

@keyframes typingMessage {
    0% {
        background-color: var(--color-teal-blue);
    }

    50% {
        background-color: var(--color-white);
    }

    60% {
        background-color: var(--color-teal-blue);
    }
}

/* End Chat */


.experience-controls {
    display: flex !important;
}

.operation-control {
    width: 4rem !important;
}

/* ENd */


.dummy-cards-container {}

.list-loading-content {
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem !important;
    margin-left: 0.5rem;
}


/* Dashboard Start */
.dashboard-card {
    position: relative;
    display: -webkit-box;
    min-width: 0;
    background-color: var(--color-white);
    background-clip: border-box;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px rgba(150, 161, 169, 0.1);
    overflow: hidden;
    display: flex;
    height: 100%;
}

.dashboard-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.dashboard-card-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-tangaroa);
}

.dashboard-card-number {
    font-size: 20px;
    font-weight: 800;
}

.dashboard-card-sub-number {
    margin-left: 3%;
    font-size: 12px;
    font-weight: 400;
    width: max-content;
    padding: 2px 10px;
    border-radius: 5px;
    border: 1px solid #097421;
    color: var(--color-white);
}

.dashboard-card-circle {
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    border-radius: 50%;
    background-color: var(--color-gray);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-card-sub-number-success {
    border: none;
    background-color: var(--color-persian-green);
}

.dashboard-card-sub-number-danger {
    border: none;
    background-color: var(--color-sundown);
}

.dashboard-card-info {
    font-size: 14px;
    font-weight: 500;
}

.dashboard-loader {
    height: 30px;
}

/* Dashboard End */


.dashboard-container {
    height: 100%;
    background-color: var(--color-white);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.dashboard-header {
    background: rgb(235 238 245 / 80%);
    padding: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    background-repeat: no-repeat;
    background-position: right;
}

.dashboard-header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-tangaroa);
}

.dashboard-header-sub-title {
    font-weight: 600;
    font-size: 33px;
    line-height: 50px;
    color: var(--color-tangaroa);
}

.dashboard-body {
    padding: 1rem;
}

.dashboard-section {
    justify-content: space-between !important;
    display: flex !important;
    margin-bottom: 1rem;
}

.dashboard-title {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: var(--color-tangaroa);
}

.dashboard-date-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.dashboard-btn {
    font-weight: 400;
    background-color: var(--color-solitude);
}

.dashboard-btn:hover {
    background-color: var(--color-solitude-dark);
}

.dashboard-btn-active {
    font-weight: 600;
    color: var(--color-white);
    background-color: var(--color-charlotte);
}

/* dashboard-table */
.dashboard-table thead th {
    background-color: var(--color-solitude);
}

.dashboard-table thead th:first-child {
    border-radius: 10px 0 0 10px;
}

.dashboard-table thead th:last-child {
    border-radius: 0 10px 10px 0;
}

/* remove border from th... */
.dashboard-table>thead>tr>th {
    border: none !important;
}

/* remove border from last tbody row... */
.dashboard-table>tbody>tr:last-child>td,
.dashboard-table>tbody>tr:last-child>th {
    border: none;
}

/* Dashboard End */

/* Toplist Dashboard Start*/
.toplist-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #666666;
}

.toplist-progress {
    margin: 20px auto;
    padding: 0;
    width: 90%;
    height: 18px;
    overflow: hidden;
    background: #e5e5e5;
    border-radius: 16px;
}

.toplist-bar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: cornflowerblue;
}

.toplist-percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-family: tahoma, arial, helvetica;
    font-size: 12px;
    color: white;
}

/* Toplist Dashboard End */


.feed-schedule-container {
    border-radius: 10px;
}


/* .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    border-radius: 20px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.e-schedule .e-month-view .e-appointment {
    border-radius: 12px;
    padding-left: 5px;
} */



.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    max-width: max-content;
    border-radius: 20px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;

    background-color: #ffffff !important;
    color: black;
    border: 1px solid;
    border-right-width: 40px;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment:after {
    /* content:"✔";
    border-right:60px solid yellow;
    color:red;
    position: absolute;
    text-align: center;
    position: absolute;
    width:0rem; */
}


.e-schedule .e-month-view .e-appointment {
    border-radius: 12px;
    padding-left: 5px;
}

/* .e-appointment{
    width: 149px;
    top: 0px;
    background-color: inherit;
    color: black;
    border: 1px solid red;
} */


/* Schedule Tooltip */
.e-tooltip-wrap.e-popup {
    background-color: #FFFFFF !important;
    border-color: #c0c0c0 !important;
}

.e-tooltip-wrap .e-tip-content {
    color: #000000 !important;
    padding: 0.75rem !important;
}


/* Google map */
.map-custom-tooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    color: #000000;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    pointer-events: none;
    z-index: 9999;
}

.map-custom-tooltip tr td:first-child {
    font-weight: 400 !important;
    color: #222fb9;
}



.map-info-card {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background-color: var(--color-gray);
    /* box-shadow: 0px 12px 25px 0px rgb(0 0 0 / 20%); */
    padding: 1rem;
    border-radius: 10px;
}

.map-info-icon {
    width: 20px !important;
    transition: width 0.3s ease;
}

.map-info-icon:hover {
    width: 36px !important;
}

.map-info-card p {
    font-size: 14px;
    color: var(--color-tangaroa);
    margin-top: 10px;
}

/* route-tracking Start */
.route-tracking-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.route-tracking-card-image-dimensions {
    width: 5rem;
    height: 5rem;
}

.route-tracking-card-image {
    border-radius: 10rem;
}

.route-tracking-card-image-container {
    padding: 1rem !important;
    text-align: center !important;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    position: relative;
}

.route-tracking-card {
    flex: 1 0 0%;
    margin: 10px;
    transition: 0.2s;
    cursor: pointer;
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    min-width: 400px;
}

.route-tracking-card:hover {
    /* background-color: lavender !important; */
    box-shadow: 0px 1px 9px 0px #dddedf;
}

p {
    margin-bottom: 0 !important;
}

.route-tracking-card .status {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 20px;
    gap: 10px;

    position: absolute;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 10px;

    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-tangaroa);
    background: #c5c5c5;
}


.route-tracking-card .route-date {
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: var(--color-tangaroa);
}

.route-tracking-card .person-name {
    font-weight: 600;
    width: 85%;
    word-break: break-all;
}


.image-loader-position {
    position: absolute !important;
}

.selector-container {
    display: flex;
    justify-content: flex-end;
}

.route-tracking-card-selected {
    background-color: #dee2e6 !important;
}

.route-tracking-card-title {
    display: flex;
    align-items: center;
}

.route-tracking-card-detail {
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
    opacity: 0.7;
}

.card-text-dark {
    opacity: 1 !important;

}


.controls-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.badge-dispatch {
    color: #212529;
    background-color: yellow;
}


.wrapper-custom-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 1.25rem;
}

/* First we style the container element.  */
.custom-calendar {
    margin: 5px 0px 5px 0px;
    padding-top: 5px;
    float: left;
    width: 80px;
    background: #ededef;
    background: -webkit-gradient(linear, left top, left bottom, from(#ededef), to(#ccc));
    background: -moz-linear-gradient(top, #ededef, #ccc);
    font: bold 30px/60px Arial Black, Arial, Helvetica, sans-serif;
    text-align: center;
    color: var(--color-tangaroa);
    text-shadow: var(--color-white) 0 1px 0;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 2px 2px #888;
}

/* Em element is also styled, it contains the month’s name. */
.custom-calendar em {
    display: block;
    font: normal bold 11px/30px Arial, Helvetica, sans-serif;
    color: #fff;
    text-shadow: #00365a 0 -1px 0;
    background: var(--color-teal-blue);
    background: -webkit-gradient(linear, left top, left bottom, from(#04599a), to(#00365a));
    background: -moz-linear-gradient(top, #04599a, #00365a);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 1px solid #00365a;
}

/* Now I am styling the pseudo elements. Container’s pseudo elements (:before and :after) are used to create thos circles, "holes in te paper". */
.custom-calendar:before,
.custom-calendar:after {
    content: '';
    float: left;
    position: absolute;
    top: 5px;
    width: 8px;
    height: 8px;
    background: #111;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 1px 1px #fff;
}

.custom-calendar:before {
    left: 11px;
}

.custom-calendar:after {
    right: 11px;
}

/*…and em’s pseudo elements are used to create the rings: */
.custom-calendar em:before,
.custom-calendar em:after {
    content: '';
    float: left;
    position: absolute;
    top: -5px;
    width: 4px;
    height: 14px;
    background: #dadada;
    background: -webkit-gradient(linear, left top, left bottom, from(#f1f1f1), to(#aaa));
    background: -moz-linear-gradient(top, #f1f1f1, #aaa);
    z-index: 2;
    border-radius: 2px;
}

.custom-calendar em:before {
    left: 13px;
}

.custom-calendar em:after {
    right: 13px;
}



.route-tracking-lead-modal {
    max-width: 1290px !important;
}

.route-tracking-lead-modal .modal-content .modal-body {
    max-height: 70vh;
    overflow: auto;
}

.route-tracking-lead-modal-pipeline-card {
    /* width: 400px; */
    min-width: 398px;
    max-width: 398px;
}


.leads-details-tracking-card {
    background: #FFFFFF;
    background: var(--color-white);
    border: 1px solid #E6EAF3;
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    box-shadow: rgb(224 229 238) 0px 7px 0px;
}

.leads-details-tracking-card:hover {
    box-shadow: rgb(224 229 238) 0px 7px 4px 0px;
}


.input-hide {
    display: none !important;
}

.checkbox {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 10px #aaaaaa;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.checkbox span.checker {
    color: var(--color-tangaroa) !important;
}

.checkbox-sm {
    width: 1rem;
    height: 1rem;
}

.checker-sm {
    font-size: medium;
}

.checkbox-md {
    width: 1.5rem;
    height: 1.5rem;
}

.checker-md {
    font-size: large;
}

.checkbox-lg {
    width: 2rem;
    height: 2rem;
}

.checker-lg {
    font-size: x-large;
}

.round {
    border-radius: 25rem;
}

.job-inactive-checker {
    display: inline-flex !important;
}

.job-checker-wrapper {
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.non-copyable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* syncfusion Schedule Delete Modal */
.e-dlg-modal {
    width: 350px !important;
}

.pdf-export-btn {
    padding-inline: 17px;
    font-size: 16px;
    font-weight: 500;
}



.search-bar {
    background-color: var(--color-solitude);
    flex: 1;
    border-radius: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;

    border-radius: 6px;
    border: none;
    padding: 0rem 0.5rem;
}

.search-bar-control {
    width: 100%;
    font-size: large;
    color: var(--color-tangaroa) !important;
    border: none !important;
    background-color: inherit !important;
}

.search-bar span {
    margin-right: 0.5rem;
    color: var(--color-tangaroa) !important;
    font-size: large;
}

.search-bar-control:focus {
    -webkit-box-shadow: none !important;
}

.search-icon-btn,
.search-icon-btn i {
    cursor: pointer !important;
}


.blur {
    color: transparent;
    text-shadow: 0px 2px 4px #000;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
}

.blur-section {
    filter: blur(3px);
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
}

.convert-profile-wrapper {
    position: absolute;
    right: 1rem;
    top: calc(50% - 27px);
    /* transform: rotate(0.82deg); */
    z-index: 1;
}

.convert-profile {
    border: none;
    border-radius: 6px;
    padding: 5px 15px;
    background: #0a2544;
    color: var(--color-white);
    font-size: 11px;
}


.c-icon-box-add-to-people {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    aspect-ratio: 1;
    background-color: #1a4854;
    text-align: center;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.circle-ripple {
    background-color: #35ffc3;
    width: auto;
    height: auto;
    border-radius: 50%;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 var(--circle-ripple-color), 0 0 0 0.250em var(--circle-ripple-color), 0 0 0 1em var(--circle-ripple-color), 0 0 0 1.5em var(--circle-ripple-color);
    }

    100% {
        box-shadow: 0 0 0 0.250em var(--circle-ripple-color), 0 0 0 1em var(--circle-ripple-color), 0 0 0 1.5em var(--circle-ripple-color), 0 0 0 2.5em var(--circle-ripple-color-2);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 var(--circle-ripple-color), 0 0 0 0.250em var(--circle-ripple-color), 0 0 0 1em var(--circle-ripple-color), 0 0 0 1.5em var(--circle-ripple-color);
    }

    100% {
        box-shadow: 0 0 0 0.250em var(--circle-ripple-color), 0 0 0 1em var(--circle-ripple-color), 0 0 0 1.5em var(--circle-ripple-color), 0 0 0 2.5em var(--circle-ripple-color-2);
    }
}


.add-people-btn {
    border-bottom-left-radius: 10px;
    cursor: pointer;
}

.add-people-btn:hover {
    background-color: #0c816f !important;

}

.dial-call-button {
    background-color: transparent;
    border: 2px solid transparent;
    /* color: ; */
    /* padding: 10px 20px; */
    /* font-size: 16px; */
    cursor: pointer;
    transition: color 0.3s ease;
}

.dial-call-button:hover {
    color: #007bff;
    /* Change text color on hover */
}

.people-table {
    margin: 0;
    padding-left: 0;
    overflow-y: auto;
    width: -webkit-fill-available;
}

.people-table thead tr th {
    min-width: 200px;
}

.people-table .action-width {
    min-width: fit-content;
}

.preview-image-box {
    width: 120px;
    /* height: 120px; */
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    box-shadow: 0px 2px 0px #E0E5EE;
    border-radius: 6px;
    /* padding: 10px; */
    overflow: hidden;
}

.preview-image-box img {
    width: 100%;
    height: 100%;
}

.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.skill-card {
    padding: 1rem 1.4rem;
    border-bottom: 1px solid var(--color-solitude);
    background-color: var(--color-white);
}

.skill-card-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.skill-card-data {
    font-size: 1rem;
    color: #6c757d;
}

.skill-card-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: var(--color-gray);
    border-radius: 6px;
}

.skill-card-badge-pink {
    background: var(--color-sundown-light) !important;
    color: var(--color-sundown) !important;
}

.accordion-button-skill:hover {
    background-color: var(--color-white) !important;
}

.accordion-button-skill:focus {
    background-color: var(--bs-primary) !important;
    border: 1px solid var(--bs-primary) !important;
    font-weight: 600;
    color: var(--color-white) !important;
    border-radius: 3px !important;
    box-shadow: 0 0 0 .25rem var(--bs-primary);
}

.accordion-button-skill:not(.collapsed) {
    background-color: var(--bs-primary) !important;
    border: 1px solid var(--bs-primary) !important;
    font-weight: 600;
    color: var(--color-white) !important;
    border-radius: 3px !important;
    box-shadow: 0 0 0 .25rem var(--bs-primary);
}

.accordion-body-vocabulary {
    max-height: 525px;
    overflow-y: scroll;
}

/* Target the first and last accordion items */
.accordion-item:first-child {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.accordion-item:last-child {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

/* First accordion button should have top corners rounded */
.accordion-item:first-child .accordion-button-skill {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

/* Last accordion button should have bottom corners rounded */
.accordion-item:last-child .accordion-button-skill {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}


.setup-wizard {
    /* min-height: 730px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 35px rgba(229, 233, 241, .8);
    overflow: hidden; */
}

.setup-wizard-left-side {
    min-height: 730px;
    background-color: #0a283e;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none;
    box-shadow: 0 0 35px rgba(229, 233, 241, .8);
}

.setup-wizard-right-side {
    background-color: #fff;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    overflow: hidden;
}

.setup-wizard-right-side .card-footer {
    border-bottom-left-radius: 25px;
    background-color: inherit;
    /* border-top:none; */
}


.setup-wizard-loader {
    background: rgb(0 0 0 / 20%);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    cursor: wait;
}


/* Mobile View */
@media screen and (max-width: 720px) {
    .setup-wizard-right-side {
        order: 1 !important;
        border: none;
        border-radius: 0;
    }

    .setup-wizard-right-side .card-footer {
        position: fixed;
    }

    .setup-wizard-left-side {
        order: 0 !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 25px;


        position: sticky;
        top: 0;
        width: 100%;
    }

    .mobile-margin-bottom-3 {
        margin-bottom: 1rem;
    }
}





.v-step {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    min-height: 80px;
}

.v-stepper {
    position: relative;
}

.v-step .v-circle {
    color: #FFF;
    background-color: #ccc;
    border: 3px solid #ccc;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: inline-block;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

}

.v-step .v-line {
    top: 40px;
    left: 18px;
    height: 100%;

    position: absolute;
    border-left: 2px dashed #FFF;
}

.v-step .v-circle {
    transition: visibility 0.3s, border-color 0.3s, background-color 0.3s;
}


.v-step.v-completed .v-circle {
    visibility: visible;
    background-color: #92dde2;
    border-color: #92dde2;
}

.v-step.v-completed .v-line {
    border-left: 3px solid #92dde2;
    border-left: 2px solid #92dde2;
}

.v-step.v-active .v-circle {
    visibility: visible;
    border-color: #F02469;
    background-color: #F02469;
}



.v-step.v-empty .v-circle {
    visibility: hidden;
}

.v-step.v-empty .v-line {
    top: 0;
    height: 150%;
}

.v-step:last-child .v-line {
    border-left: 3px solid white;
    z-index: -1;
}

.v-content {
    display: inline-block;
    padding-top: 10px;
    margin-left: 20px;
    color: white;
}


.badge {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 12px;
    color: white;
    font-size: 12px;
    text-align: center;
    min-width: 80px;
}

.badge-enrich {
    /* background-color: #007bff; */
    background-color: #ffc107;
    color: black;
}

.badge-sales {
    background-color: #28a745;
}

.badge-nurture {
    background-color: #0dcaf0;
}

.badge-change {
    background-color: #9c6bed;
}

.badge-prospect {
    background-color: #2D95F0;
}

.badge-disqualified {
    background-color: #6c757d;
}

.badge-bogus {
    background-color: #dc3545;
}


.loader-table {
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
    align-items: center;
    /* Vertically center the content */
    height: 100%;
    /* Ensure the cell takes the full height */
    height: 400px;
}

.portal-card {
    border: 1px solid #ccc;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}


/*
*
* ==========================================
* CUSTOM LOGIN CLASSES
* ==========================================
*
*/
.login,
.image {
    min-height: 100vh;
    align-items: center;
}

.login-container {
    margin-top: 0px !important;
}

.bg-image {
    /* background-image: url(../../../assets/images/login/laptop.png); */
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    background-position: center center;
    background-color: #e5cdf3;
    /* background-color: #1e222e; */
    color: #FFFFFF;
    /* background: radial-gradient(circle, #2b2547, #1f2230, #1f232e); */
}

.btn-login {
    color: #fff;
    background-color: #1b4a55;
    border-color: #1b4a55;
}

.btn-login:hover {
    color: #fff;
    background-color: #0e3d47;
    border-color: #0e3d47;
}

.login-text {
    text-align: center;
    font-size: 2rem;
    line-height: 1.2
}

.card-wizard {
    background-color: #FFF;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-right: none;
}

.img-responsive {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.setting-container {
    background: #f4f7fb !important;
}

.icp-description {
    height: 70vh;
}

.contact-card {
    position: relative;
    margin: 1rem;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: rgb(224 229 238) 0px 7px 0px;
    overflow: hidden;
    min-width: 790px;
    flex: 1 1;
    transition: box-shadow 0.3s ease-in-out;
    max-height: fit-content;
}

.contact-card:hover {
    box-shadow: rgb(224 229 238) 0px 7px 4px 0px;
}

.contact-card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.contact-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    /* min-height: 150px; */
}

.contact-card-body p {
    font-size: 13px;
    margin: 0 0 40px;
}

.contact-card-user {
    display: flex;
    /*margin-top: auto;*/
}

.contact-card-user-img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.contact-card-user-info {
    /* margin-right: 15px; */
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: var(--color-alice-blue-100);
    padding: 1rem;
    min-height: 78px;
}

.contact-card-user-info-col-150 {
    min-width: 150px;
}

.contact-card-user-info-col-250 {
    min-width: 250px;
}

.contact-card-user-info h5 {
    margin: 0;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-tangaroa);
}

.contact-card-user-info small {
    color: var(--color-tangaroa);
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}

.contact-card-user-details {
    display: flex;
    flex-direction: column;
}

.contact-card-user-name {
    padding-left: 1rem;
    font-weight: 500;
    font-size: 20px;
    color: var(--color-tangaroa);
}

.contact-card-user-location {
    display: inline-flex;
    align-items: center;
    padding-left: 1rem;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-tangaroa);
    opacity: 0.6;
}

.contact-card-user-locatio-text {
    font-size: 12px;
    padding-right: 1rem;
}

.contact-card-user-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}


.contact-card-photo {
    width: 60px;
    height: 60px;
    padding: 2px;
    background-color: var(--color-white);
    border: 1px solid var(--bs-primary);
    border-radius: 10px;
}

.contact-card-photo-box {
    height: 100%;
    background: var(--bs-primary);
    border-radius: 8px;
}

.contact-card-photo-box img {
    width: 100%;
}

.contact-card-photo-box-text {
    height: 100%;
    display: -ms-flexbox;
    display: flex;

    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-white);
}

.contact-card .status {
    padding: 6px 20px;
    gap: 10px;
    position: absolute;
    height: 32px;
    right: 0px;
    top: 0px;
    border-radius: 0px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-tangaroa);
    background: #c5c5c5;
}

.contact-card-expand-toggle {
    cursor: pointer;
    text-align: center;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    font-weight: 500;
    transition: background 0.3s ease-in-out;
}

.contact-card-expand-toggle:hover {
    background: #e3e3e3;
}

.contact-card-expandable-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
}

.contact-card-expandable-content.expanded {
    max-height: 200px;
    opacity: 1;
}

.contact-card-expandable-btn {
    cursor: pointer;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
}

.contact-card-badge {
    display: inline-block;
    padding: 1px 15px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    text-transform: uppercase;
    margin-left: 8px;
}

.contact-card-badge-success {
    background-color: #28a745;
    color: white;
}

.contact-card-badge-danger {
    background-color: #dc3545;
    color: white;
}


.status-enrich {
    background: #ffcb9a !important;
}

.status-sales-approach {
    background: var(--color-aero-green-light) !important;
}

.status-nurture {
    background: #e4e3e3 !important;
}

.status-change-communication {
    background: #e1caff !important;
}

.status-prospect {
    background: #73c0fa !important;
}

.status-disqualified {
    background: #fbe3e3 !important;
}

.status-bogu {
    background: #FFBFC3 !important;
}

.custom-table th:first-child {
    border-left: none;
}

.custom-table th:last-child {
    border-right: none;
}

.custom-table th {
    border: 1px solid #ddd;
}

.custom-table td {
    font-size: 14px;
    font-weight: 400;
    /* font-weight: 300; */
}

.hubspot-meeting-modal-body {
    padding: 0px !important;
}

.iframe-hubspot-meeting {
    width: 100%;
    height: 82vh;
    border: none;

    /* transform: scale(0.7);  */

}

.selector-helper-text-ul {
    width: 100%;
    max-height: fit-content;
    max-height: 500px;
    overflow-y: auto;
}

.selector-helper-text-option-name {
    font-size: 14px;
    font-weight: 500;
}

.selector-helper-text-option-subname {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-dark);
}




.selector-helper-btn::after {
    /* margin-left: 0;
    vertical-align: middle;
    margin-right: 10px;
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: translateY(-50%) rotate(45deg); */

    position: absolute;
    top: 35%;
    right: 15px;
}

.accordion-button-skill::after {
    margin-left: auto;
}
