
  
  /* Glassmorphic Card */
  .ConnectionLoaderEffect-container {
    width: 450px;
    /* height: 140px; */
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    box-shadow: inset 0 10px 40px rgb(123 93 214 / 17%);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    /* border: 2px solid rgba(255, 255, 255, 0.2); */
    overflow: hidden;
  }
  
  /* Step Text */
  .ConnectionLoaderEffect-step {
    font-size: 22px;
    font-weight: 600;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  }
  
  .ConnectionLoaderEffect-step.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Continuous Loader Dots */
  .ConnectionLoaderEffect-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  
  .ConnectionLoaderEffect-dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #7b5dd6;
    border-radius: 50%;
    animation: ConnectionLoaderEffect-blink 1.4s infinite ease-in-out;
  }
  
  .ConnectionLoaderEffect-dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .ConnectionLoaderEffect-dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes ConnectionLoaderEffect-blink {
    0%, 100% { opacity: 0.2; transform: translateY(0); }
    50% { opacity: 1; transform: translateY(-3px); }
  }
  
  /* Glowing Effect */
  .ConnectionLoaderEffect-glow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(123, 93, 214, 0.3);
    filter: blur(20px);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .ConnectionLoaderEffect-step.show + .ConnectionLoaderEffect-glow {
    opacity: 1;
  }
  
  /* Gradient Border Animation */
  @keyframes ConnectionLoaderEffect-borderGlow {
    0% { border-color: rgba(123, 93, 214, 0.5); }
    50% { border-color: rgba(123, 93, 214, 0.8); }
    100% { border-color: rgba(123, 93, 214, 0.5); }
  }
  
  .ConnectionLoaderEffect-container {
    animation: ConnectionLoaderEffect-borderGlow 3s infinite alternate ease-in-out;
  }
  

/* Title Styling */
.ConnectionLoaderEffect-title {
    font-size: 28px;
    font-weight: bold;
    color: var(--primary);
    position: relative;
    
    text-shadow: 0 0 10px rgba(123, 93, 214, 0.6);
    display: inline-block;
    animation: ConnectionLoaderEffect-wave 1.5s ease-in-out infinite alternate;
}

/* Icon Animation */
.ConnectionLoaderEffect-title i {
    display: inline-block;
    animation: ConnectionLoaderEffect-rotate 2s linear infinite;
}

/* Wave Text Effect */
@keyframes ConnectionLoaderEffect-wave {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-2px); }
    100% { transform: translateY(0px); }
}

/* Rotating Icon Effect */
@keyframes ConnectionLoaderEffect-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}