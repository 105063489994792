.wizard-container{
    margin-top: 100px;
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px dashed #DEE3EE;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px dashed #DEE3EE;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: #FFF;
}

.stepper-item.active {
    font-weight: bold;
    color: #FFF;
}

.stepper-item.active .step-counter {
    font-weight: bold;
    background-color: #F02469;
    /* border-color: var(--bs-primary); */
    background-color: var(--bs-primary);
}

.stepper-item.completed .step-counter {
    background-color: #92DDE2;
    background-color: var(--bs-primary);
}

.step-name {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #92DDE2;
    border-bottom: 2px solid var(--bs-primary);
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
}

.stepper-item:first-child::before {
    content: none;
}

.stepper-item:last-child::after {
    content: none;
}

.stepper-item-heading {
    font-weight: 400;
    font-size: 27px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}

.stepper-item-sub-heading {
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    opacity: 0.7;
}

.stepper-item-details {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}

.CircularProgressbar .CircularProgressbar-path{
    stroke : var(--bs-primary) !important;
}
