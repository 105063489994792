.home-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  /* Header Styling */
  .header {
    padding: 20px;
    color: white;
    background-color: #e0e0e0;
    background-color: #b6a9d287;
  }
  
  /* Main content styling */
  .main-content {
    margin-top: 50px;
  }
  
  /* Button Styling */
  .connect-button {
    width: auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: var(--bs-primary);
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .connect-button:hover {
    background-color: var(--bs-btn-hover);
  }