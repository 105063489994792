body {
    /* font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    /* color: #6c757d; */
    line-height: 1.6666;
    /* font-weight: 400; */
}

/* *,
::after,
::before {
    box-sizing: border-box;
} */


.page,
.hidd {
    overflow: hidden;
}

.bg--fixed,
.bg--scroll {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg--scroll {
    background-attachment: fixed !important;
}

.font--jakarta .section-id {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.color--white,
.color--white h1,
.color--white h2,
.color--white h3,
.color--white h4,
.color--white h5,
.color--white h6,
.color--white p,
.color--white a,
.color--white li,
.color--white i,
.color--white span {
    color: #fff;
}



.section-id {
    display: block;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.register-page-txt h2 {
    line-height: 1.15;
    font-weight: 700;
    margin-bottom: 6px;
}

p.p-md {
    font-size: 1.0625rem;
}

.rel,
section,
.container {
    position: relative !important;
    z-index: 3;
}

.r-16 {
    /* -webkit-border-radius: 16px; */
    -moz-border-radius: 16px;
    -o-border-radius: 16px;
    border-radius: 16px;
}


.bg--fixed {
    background-attachment: scroll !important;
}

.login,
.signup {
    background: url(../../assets/images/login.jpg);
    background-position: bottom left;
    min-height: 100vh;
    padding-top: 68px;
    padding-bottom: 80px;
}

.register-page-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12);
}

.login .register-page-wrapper:after,
.signup .register-page-wrapper:after {
    position: absolute;
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #fff;
}

.signup .register-page-wrapper {
    /* background: url(../../assets/images/login-wrapper.jpg); */
    /* background-position: right center; */
}

.signup .register-page-white {
    /* background: #fff; */
}

.signup .register-page-img {
    background: url(../../assets/images/login-wrapper.jpg);
    background-position: right center;
    background-size: cover;
}

.login .register-page-img {
    background: url(../../assets/images/login-wrapper.jpg);
    background-position: left center;
}

.signup .register-page-wrapper:after {
    left: 0;
}

.signup .register-page-copyright {
    position: absolute;
    right: 60px;
    bottom: 45px;
}

.login .register-page-txt,
.signup .register-page-txt {
    padding: 70px 60px;
}



.register-page-form {
    padding: 60px;
}




/*------------------------------------------*/
/*  REGISTER PAGE TYPOGRAPHY
/*------------------------------------------*/

.register-page-form p.input-header {
    position: relative;
    color: #353f4f;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 12px !important;
    padding-left: 5px;
}

.reset-password-link p {
    position: relative;
    font-size: 0.9rem;
    line-height: 1;
    margin-top: -8px;
    margin-bottom: 10px;
}

.form-data span {
    display: block;
    font-size: 0.85rem;
    margin-bottom: 0;
}

.form-data span a {
    color: #353f4f;
    font-weight: 500;
    text-decoration: underline;
}

.register-page-form p.create-account {
    line-height: 1;
    margin-top: 30px;
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 1rem;
    color: var(--bs-gray);
}

.register-page-form p.create-account a {
    font-weight: 600;
    text-decoration: underline;
}



/*------------------------------------------*/
/*  REGISTER PAGE SEPARATOR LINE
/*------------------------------------------*/

.separator-line {
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    /* font-family: 'Plus Jakarta Sans', sans-serif; */
    font-size: 1rem;
    font-weight: 400;
    margin: 25px 0px 30px;
    color: var(--bs-gray);
}

.separator-line::before,
.separator-line::after {
    content: "";
    border-top: 2px solid rgba(0, 0, 0, .1);
    margin: 0 15px 0 0;
    flex: 1 0 20px;
}

.separator-line::after {
    margin: 0 0 0 15px;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM BUTTON
/*------------------------------------------*/

.btn--theme {
    color: #fff !important;
    border-color: #7b5dd6 !important;
    background-color: #7b5dd6 !important;
}

.btn--theme:hover {
    color: #fff !important;
    border-color: var(--bs-btn-hover) !important;
    background-color: var(--bs-btn-hover) !important;
}

.signup .register-page-form .btn.submit,
.login .register-page-form .btn.submit {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    font-weight: 600;
}

.reset-page-wrapper .btn.submit {
    width: 100%;
    margin-bottom: 20px;
}

.register-page-form .btn-google {
    width: 100%;
    color: #333;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 0.8rem 1.7rem;
    font-weight: 600;
}

.btn-google img {
    position: relative;
    width: 22px;
    height: 22px;
    top: -2px;
    right: 5px;
}

.register-page-form .btn-google:hover {
    border: 1px solid var(--bs-btn-hover);
}


.register-form-title {
    margin-bottom: 30px;
}

/* .login .register-page-txt,
.signup .register-page-txt {
    padding: 50px 30px 0;
} */

.signup .register-page-txt h2 {
    line-height: 1;
}

.register-page-txt p.mt-25 {
    margin-top: 20px;
}

.reset-password-link p {
    margin-top: 0;
    margin-bottom: 10px;
}

.signup .form-data span {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--bs-gray);
    line-height: 1.6666;
}

.login .register-page-copyright {
    position: absolute;
    left: 60px;
    bottom: 45px;
}

/* .signup .register-page-copyright {
    right: 30px;
    bottom: 40px;
} */

.register-page-copyright p {
    font-size: 0.95rem;
}

.login .register-page-form {
    padding: 60px;
}

.signup .register-page-form {
    padding: 45px 25px 45px 35px;
}

.login.login-1 .register-page-form,
.signup.signup-1 .register-page-form {
    padding: 10px 30px;
}

/* .separator-line {
    font-size: 1rem;
    margin: 20px 0;
} */




.reset-password-link p {
    font-size: 1rem;
    margin-top: -3px;
    margin-bottom: 10px;
}

.form-data span {
    font-size: 0.875rem;
}

.reset-page-wrapper .form-data span {
    font-size: 1rem;
}



.reset-form-title {
    margin-bottom: 20px;
}

.reset-form-title p.p-sm {
    font-size: 1rem;
    margin-top: 15px;
}

.reset-page-wrapper {
    margin: 0 30px;
}

.reset-page-wrapper form {
    padding: 35px 25px 10px;
}

.reset-page-wrapper .form-control,
.register-page-form .form-control,
.register-page-form .input-group-text {
    height: 50px;
    font-size: 1.0625rem;
    padding: 5px 15px;
    /* margin-bottom: 20px; */
}



.register-page-txt img {
    max-height: 26px;
    margin-bottom: 40px;
}

.login-page-logo {
    margin-bottom: 40px;
}

.login-page-logo img {
    max-height: 35px;
}

.register-form-title {
    margin-bottom: 30px;
}

/* .login .register-page-txt,
.signup .register-page-txt {
    padding: 50px 30px 0;
} */

.signup .register-page-txt h2 {
    line-height: 1;
}

.register-page-txt p.mt-25 {
    margin-top: 20px;
}

.reset-password-link p {
    margin-top: 0;
    margin-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1199.95px) {

    .login .register-page-txt,
    .signup .register-page-txt {
        padding: 60px 45px;
    }
}




/* ==========================================================================
  34. COLOR SCHEME
  ========================================================================== */
.color--theme,
.color--theme h1,
.color--theme h2,
.color--theme h3,
.color--theme h4,
.color--theme h5,
.color--theme h6,
.color--theme p,
.color--theme a,
.color--theme li,
.color--theme i,
.color--theme span,
.color--white .color--theme {
    color: #7b5dd6 !important;
}