.card-left {
    min-height: 730px;
    background-color: #FFFFFF;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none;

    box-shadow: 0px 0px 35px rgba(229, 233, 241, 0.8);
}

.card-left .card-header{
    background-color: inherit;
    border-top-left-radius: 25px;
    border-bottom: none;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);

}

.card-left .card-body{
    max-height: 551px;
    /* max-height: 567px; */
    overflow: auto;
}

.card-left .card-footer {
    border-bottom-left-radius: 25px;
    background-color: inherit;
    /* border-top:none; */
}

.card-left-header {
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #0A253E;
}

.card-left-sub-header {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #0A283E;
    opacity: 0.7;
}

.card-right {
    background-color: #0A283E;
    background: radial-gradient(circle, #2b2547, #1f2230, #1f232e);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
}

.e-custom-redio-btn {
    border: solid #efb955;
    border-radius: 3em;
}

/* Mobile View */
@media screen and (max-width: 720px) {
    .card-left {
        order: 1 !important;
        border: none;
        border-radius: 0;
    }

    .card-left .card-footer {
        position: fixed;
    }

    .card-right {
        order: 0 !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 25px;


        position: sticky;
        top: 0;
        width: 100%;
    }

    .mobile-margin-bottom-3{
        margin-bottom: 1rem;
    }
}

.congratulations {
    margin-top: 300px;
}

.register-img {
    width: 100;
}