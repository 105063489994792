.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.nav-logo {
  width: 250px;
}



/******************************************************************************
/*************************     Custome CSS          ***************************
/*****************************************************************************/

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #ffffff;
  background-image: linear-gradient(0deg, rgb(177, 72, 196) 0%, rgba(186, 104, 200, 1) 26%, rgb(186, 104, 200) 100%);
  border: 3px solid #ffffff;
  background-image: linear-gradient(0deg, rgb(85, 59, 163) 0%, rgba(123, 93, 214) 26%, rgb(160, 135, 235) 100%);
}





:root {
  /* --primary: #ba68c8; */
  /* --bs-primary: #ba68c8; */
  --primary: #7b5dd6;
  --bs-primary: #7b5dd6;
  --bs-primary-rgb: 123, 93, 214;
}

.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn-primary:hover {
  background-color: var(--bs-btn-hover) !important;
  border-color: var(--bs-btn-hover) !important;
}

.form-check-input:checked {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.form-check-input:focus {
  border-color: var(--bs-primary) !important;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25) !important;
}

.form-control:focus {
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
}

.form-select:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(var(--bs-primary-rgb), 0.25);
}

.dropdown-item.hover,
.dropdown-item:active {
  background-color: var(--bs-primary);
}

.Toastify__close-button {
  width: auto;
}

/* label,
span {
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
} */

/* label{
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
} */


.app-container-loader {
  background: rgb(0 0 0 / 20%);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  cursor: wait;
}


.error-label {
  color: #dc3545 !important;
}

.invalid-input {
  border: 1px solid #dc3545 !important;
}


.new-card-ui {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  /* margin: 7em auto; */
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);

  height: 80vh;
  margin: 10vh auto;
  overflow-y: auto;
}


/* LoginPage.css */
.login-container {


  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  margin: 10vh auto;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);


  ;

}


.form-group {
  margin-bottom: 15px;
}



input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: var(--bs-btn-hover);;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}



/******************************************************************************
/*************************     Login CSS          ***************************
/*****************************************************************************/

.login-page-image {
  width: 400px;
  width: 100%;
}






/******************************************************************************
/*************************     Dashboard CSS          ***************************
/*****************************************************************************/



/* .container {
  width: 90%;
  max-width: 1200px;
  text-align: center;
} */


.dashboard {
  display: flex;
  flex-wrap: wrap;
  /* Allow wrapping to new rows */
  justify-content: space-between;
  gap: 20px;
}

.dashboard-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-section-title {
  /* font-size: 2rem; */
  /* color: #333; */
  margin-bottom: 20px;
  width: 100%;
}

.dashborad-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: calc(25% - 20px);
  /* Each card takes up 25% of the row width (with 20px gap) */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  justify-content: space-between;

  align-items: center;
}

.dashborad-card h3 {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.dashborad-card .count {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0;
}

.followup-1 {
  background-color: #28a745b3;
  color: white;
}

.followup-nurture {
  background-color: #e4b31f;
  color: white;
}

.followup-channel {
  background-color: #17a2b8;
  color: white;
}

.followup-enrich {
  background-color: #c7cbd4;
  color: white;
}

.cold-prospect {
  background-color: #007bffa6;
  color: white;
}

.cold-disqualified {
  background-color: #dc3545d1;
  color: white;
}

.cold-bogus {
  background-color: #7a4e5270;
  color: white;
}

.dashborad-summary {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

.dashborad-summary h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.dashborad-summary span {
  font-weight: bold;
  color: #333;
}

/* Responsive Design - For smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
  .dashborad-card {
    width: calc(50% - 20px);
    /* On smaller screens, display 2 cards per row */
  }
}

@media (max-width: 480px) {
  .dashborad-card {
    width: 100%;
    /* On very small screens, display 1 card per row */
  }
}






/* Social button */
.social-btn {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 10px 24px;
  background-color: var(--primary);
  border-radius: 50rem;
  border: none;
  line-height: normal;
  /* grid-gap: 10px;
  gap: 10px; */
}

.social-btn:hover {
  background-color: #8c52ff24;
  ;
  /* color: #fff; */
}

.social-btn:active {
  transform: translateY(-2px);
}

.social-btn:disabled {
  opacity: 0.8;
  transform: none;
}

.social-btn-with-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-btn-light-btn {
  background: #FFF;
  color: var(--primary);
  border: 1px solid var(--primary);
}